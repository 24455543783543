
import { defineComponent, onMounted } from "vue";
import ApprovalList from "@/components/pages/approval/ApprovalList.vue";
import {
  setCurrentPageTitle,
  setCurrentPageBreadcrumbs,
} from "@/core/helpers/breadcrumb";

export default defineComponent({
  components: { ApprovalList },
  name: "approval-list",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Approval");
      setCurrentPageBreadcrumbs("Approval List", []);
    });
  },
});
