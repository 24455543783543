
import { defineComponent, inject, ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import imagesAsset from '@/assets/ts/images.js';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import { useDropzone } from 'vue3-dropzone';
import _ from 'lodash';

export default defineComponent({
  name: 'upload-image',
  props: {
    loadImage: {
      type: Array,
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const images = inject('$images');
    const addPhoto = imagesAsset.addPhoto;
    const iconPdf = imagesAsset.pdf;
    const tempFile = ref({
      filesDoc: [] as any,
    });

    const warning = ref('');

    let photo = reactive([]) as any;

    function onDrop(acceptFiles, rejectReasons) {
      if (rejectReasons.length) {
        const findError = rejectReasons.map((err) => {
          return err.errors[0].message;
        });
        warning.value = findError[0];
      } else {
        if (/\.(jpg|jpeg|png|webp|avif|gif|JPG|JPEG|PNG)$/.test(acceptFiles[0].name)) {
          photo = acceptFiles;
          PhotoName.value = photo[0].name;
          warning.value = '';
        }
      }
    }

    const documentName = ref('');
    const PhotoName = ref('');

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept: 'image/*',
      maxSize: 5000000,
      multiple: true,
    });

    const counterImage = ref(5);

    const loadingFile = ref(false);

    const isImgUrl = (url) => {
      if (/\.(pdf)$/.test(url)) {
        return 'pdf';
      } else if (/\.(docx)$/.test(url)) {
        return 'docx';
      } else {
        return 'xlsx';
      }
    };
    const editStatus = ref(false);
    const selectedEdit = ref({}) as any;

    const changeImg = (img) => {
      editStatus.value = true;
      dialogVisible.value = true;
      selectedEdit.value = img;
    };

    const setPrimary = (img) => {
      forms.attachment.forEach(function (v) {
        delete v.utama;
      });

      const itemToFind = img;

      const foundIdx = forms.attachment.findIndex((el) => el == itemToFind);
      forms.attachment.splice(foundIdx, 1);
      forms.attachment.unshift(itemToFind);

      forms.attachment.find((o, i) => {
        if (o.url === img.url) {
          forms.attachment[i] = {
            url: img.url,
            filename: img.filename,
            extension: img.extension,
            filesize: img.filesize,
            size_unit: img.size_unit,
            utama: 1,
          };
        }
      });
    };

    const selectedFile = async () => {
      loadingFile.value = true;
      const formData: any = new FormData();
      photo.map((x) => {
        formData.append('files', x);
      });
      await store
        .dispatch(Actions.FILE_UPLOAD_ASSET, formData)
        .then((res) => {
          if (!editStatus.value) {
            res.data.map((z) => {
              const objPhoto = {
                url: z.url,
                filename: z.filename,
                extension: z.extension,
                filesize: z.filesize,
                size_unit: z.size_unit,
                asset_document_type_id: '631f42a2105c9a39312bd3a9',
              };
              forms.attachment.push(objPhoto);
            });
          } else {
            forms.attachment.find((o, i) => {
              if (o.url === selectedEdit.value.url) {
                res.data.map((z) => {
                  forms.attachment[i] = {
                    url: z.url,
                    filename: z.filename,
                    extension: z.extension,
                    filesize: z.filesize,
                    size_unit: z.size_unit,
                    asset_document_type_id: '631f42a2105c9a39312bd3a9',
                  };
                });
                editStatus.value = false;
              }
            });
          }
          emit('arrayPhoto', forms.attachment);
          photo = [];
          PhotoName.value = '';
          counterImage.value--;
          dialogVisible.value = false;
          loadingFile.value = false;
        })
        .catch((err) => {
          console.log(err);
          dialogVisible.value = false;
          loadingFile.value = false;
        });
    };

    const forms = reactive({
      attachment: [] as any,
    });

    const removeImage = (img) => {
      forms.attachment.splice(forms.attachment.indexOf(img), 1);
      emit('arrayPhoto', forms.attachment);
    };

    const dialogVisible = ref(false);

    watch(
      () => _.loadImage,
      (first, second) => {
        console.log(
          'Watch props.selected function called with args:',
          first,
          second
        );
        forms.attachment = _.loadImage;
        emit('arrayPhoto', forms.attachment);
      }
    );

    return {
      t,
      images,
      forms,
      addPhoto,
      loadingFile,
      dialogVisible,
      photo,
      removeImage,
      selectedFile,
      getRootProps,
      getInputProps,
      isImgUrl,
      documentName,
      warning,
      PhotoName,
      tempFile,
      iconPdf,
      setPrimary,
      changeImg,
      ...rest,
    };
  },
});
