
import { defineComponent, watch, reactive, nextTick, ref, computed } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import images from "@/assets/ts/images.js";

interface Option {
  label: string;
  option: string[];
}

export default defineComponent({
  name: "ProposeValidation",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    },
  },
  setup(_, { emit }) {
    const dialogVisible = ref(false);
    const needPolygon = images.needPolygon;
    const router = useRouter();
    const { t } = useI18n();

    const Cancel = () => {
      emit('closePropose', false);
    }

    const detail = () => {
      router.push(`/assets/update/${_.id}/location?polygon=0#GHF`);
    }

    watch(
      () => _.open,
      (first, second) => {
        dialogVisible.value = _.open;
      }
    );

    return {
      needPolygon,
      dialogVisible,
      Cancel,
      t,
      detail
    };
  },
});
