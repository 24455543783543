
import { dateFormat } from "@/core/helpers/helps";
import { defineComponent, onMounted, ref, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { setAllertError } from "@/core/services/Alert";
import { useStore } from "vuex";

export default defineComponent({
  name: "components-pages-approval-tab-need-approval",
  setup() {
    const tableData = ref([]);
    const store = useStore();

    onMounted(() => {
      getApprovalLists();
    });

    const cUser = computed(() => {
      return store.getters.currentUser;
    });

    const errors = computed(() => {
      return store.getters.getErrors;
    });

    const headerCellClassName = ({ columnIndex }) => {
      if ([0].includes(columnIndex)) return "text-end bg-secondary";
      return "bg-secondary";
    };

    const tableCellClassName = ({ columnIndex }) => {
      if ([0].includes(columnIndex)) return "text-end";

      return "";
    };

    const statusColor = (status) => {
      if (status == "APPROVED") {
        return `border-success text-success`;
      } else {
        return "border-danger text-danger";
      }
    };

    const getApprovalLists = () => {
      store
        .dispatch(Actions.APPROVAL_USER, { mode: "history" })
        .then((res) => {
          tableData.value = res.data;
        })
        .catch(() => {
          setAllertError(errors.value);
        });
    };

    return {
      tableData,
      cUser,
      headerCellClassName,
      tableCellClassName,
      statusColor,
      dateFormat,
    };
  },
});
