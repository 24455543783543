
import { checkPermission } from '@/core/helpers/helps';
import { ActionsAdminValidasi, MutationsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import { Actions } from '@/store/enums/StoreEnums';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';


export default {
  name: "reject-asset-modal",
  props: {
    open: {
      required: true,
      default: false,
      type: Boolean,
    },
    errorMessage:{
      required : true,
      default : true,
    },
    verify:{
      required : true,
      type: Boolean,
    },
    filter: {
      required: false,
    }
  },
  setup(props, { emit }) {
      const store = useStore();
      const openFeedback: any =  ref(false);
      const roleUser = computed(() => {
        return store.getters.getRole;
      });
      const getProfile = computed(() => {
        return store.getters.getUserProfile;
      });

      const feedback: any = ref('');
      const requeired = ref(false);
      const loading = ref(false)
      const { t } = useI18n();


      const close = () => {
        feedback.value = ''
        if (store.getters.ApprovalList.rejected || store.getters.ApprovalList.approved) {
          store
              .dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
                ...store.getters.ApprovalList,
                rejected : false,
                approved : false,
            })
        } else {
          store
              .dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
                ...store.getters.ValidasiList,
                rejected : false,
                approved : false,
            })
        }
      };

      const konfirmasi = () => {
        openFeedback.value = true;
        loading.value = true
        if (store.getters.ApprovalList.rejected || store.getters.ApprovalList.approved && store.getters.ApprovalList.type === 'requestUpdated') {
          // store.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
          //   ...store.getters.statusFeedback,
          //   status : true,
          //   approval : true
          // })
          
          /**
           * payload action approval request rejected
           */
          let payload = {
              ...store.getters.PayloadAction,
              user_id : getProfile.value._id,
              entity_id : store.getters.ApprovalList.entity_id === '' ? store.getters.PayloadAction.entity_id : store.getters.ApprovalList.entity_id,
              init_role : roleUser.value._id,
              action : 'ASSET.request_edit_reject',
          }
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, payload)
          postAction()
          setTimeout(() => {
            store
              .dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
                ...store.getters.ApprovalList,
                rejected : false,
                approved : false,
            })
          }, 1000);
        } else {
          // store.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
          //   ...store.getters.statusFeedback,
          //   status : true,
          //   validasi : true
          // })

          /**
           * payload action approval request rejected
           */
           loading.value = true
           let payload = {
              ...store.getters.PayloadAction,
              user_id : getProfile.value._id,
              entity_id : store.getters.ValidasiList.entity_id,
              init_role : roleUser.value._id,
              action : store.getters.ApprovalList.verified ? 'ASSET.unverify' : 'ASSET.invalidate',
          }
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, payload)
          postAction()
          setTimeout(() => {
            store
              .dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
                ...store.getters.ValidasiList,
                rejected : false,
                approved : false,
            })
          }, 1000);
        }
      }

      const postAction = () => {
          if (['asset.request_edit_reject', 'asset.invalidate'].includes(store.getters.PayloadAction.action.toLowerCase()) && feedback.value === '') {
            requeired.value = true;
          } else {
            let payload = {
                ...store.getters.PayloadAction,
                feedback : feedback.value,
            }
            store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, payload)

            setTimeout(() => {
              let action = ['asset.validate','asset.verify','asset.request_edit_approve'].includes(store.getters.PayloadAction.action.toLowerCase()) ? 'POST_BULK_STATUS_ASSET' : 'POST_STATUS_ASSET';

              store.dispatch(ActionsAdminValidasi[action]).finally(() => {
                store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, true);
                store
                .dispatch(Actions.GET_LIST_ASSETS, props.filter)
                .finally(() => {
                  store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, false);
                  setTimeout(() => {
                    location.reload()
                  }, 2000);
                });
                feedback.value = '';
              })
            }, 500)
          }
        }

    return {
      close,
      konfirmasi,
      store,
      feedback,
      requeired,
      loading,
      t
    };
  },
};
