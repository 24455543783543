
import { defineComponent, inject, ref, computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import imagesAsset from '@/assets/ts/images.js';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import { useDropzone } from 'vue3-dropzone';
import { useRouter, useRoute } from 'vue-router';
import _ from 'lodash';

export default defineComponent({
  name: 'List of Assets',
  props: {
    loadDocument: {
      type: Array,
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const images = inject('$images');

    const iconPdf = imagesAsset.pdf;
    const iconXlsx = imagesAsset.xlsx;
    const iconDocx = imagesAsset.docx;
    const iconRar = imagesAsset.rar;
    const iconCsv = imagesAsset.csv;
    const iconXml = imagesAsset.xml;

    const detectPath = computed(() => {
      return route.path;
    });
    const tempFile = ref({
      filesDoc: [] as any,
    });

    let doc = reactive([]) as any;

    function onDrop(acceptFiles, rejectReasons) {
      doc = acceptFiles;
      documentName.value = doc[0].name;

      if (rejectReasons.length) {
        const findError = rejectReasons.map((err) => {
          return err.errors[0].message;
        });
        console.log(findError[0]);
      } else {
        console.log(acceptFiles);
        doc = acceptFiles;
        documentName.value = doc[0].name;
      }
    }

    const documentName = ref('');

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept: '.xlsx,.xls,.doc, .docx,.pdf, .rar, .xml, .csv',
      multiple: true,
    });
    const counterDoc = ref(5);

    const loadingFile = ref(false);

    const selectedFileDoc = async () => {
      loadingFile.value = true;
      const formData: any = new FormData();
      doc.map((x) => {
        formData.append('files', x);
      });
      await store
        .dispatch(Actions.FILE_UPLOAD_ASSET, formData)
        .then((res) => {
          counterDoc.value--;
          res.data.map((z) => {
            const objDoc = {
              url: z.url,
              filename: z.filename,
              extension: z.extension,
              filesize: z.filesize,
              size_unit: z.size_unit,
              asset_document_type_id: '632020db87192c201e84c9ec',
            };
            forms.attachment.push(objDoc);
          });
          emit('arrayDoc', forms.attachment);
          doc = [];
          dialogVisibleDoc.value = false;
          documentName.value = '';
          loadingFile.value = false;
        })
        .catch((err) => {
          dialogVisibleDoc.value = false;
          console.log(err);
          loadingFile.value = false;
        });
    };

    const isImgUrl = (url) => {
      if (/\.(pdf)$/.test(url)) {
        return 'pdf';
      } else if (/\.(docx)$/.test(url)) {
        return 'docx';
      } else if (/\.(xlsx)$/.test(url)) {
        return 'xlsx';
      } else if (/\.(rar)$/.test(url)) {
        return 'rar';
      } else if (/\.(xml)$/.test(url)) {
        return 'xml';
      } else {
        return 'csv';
      }
    };

    const forms = reactive({
      name: '',
      address: '',
      external_code: '',
      acquisition_date: '',
      acquisition_value_rp: 0,
      acquisition_value_usd: 0,
      acquisition_status: '',
      type: '',
      channel_id: '',
      province_id: '',
      regency_id: '',
      district_id: '',
      village_id: '',
      coor: '',
      coordinate: {
        lat: '-6.953305',
        long: '107.593422',
      },
      atas_nama: '',
      start_date_certificate: '',
      end_date_certificate: '',
      document_code: '',
      document_code_support: '',
      issued_by: '',
      luas_total: '',
      luas_total_uom_id: '63168fa18c130d3fa04161da', // done
      surat_ukur: '',
      status_tanah: '',
      status_legalitas: '631676fe8c130d3fa041611f',
      keterangan_aset: [],
      peruntukan: '',
      luas_status: '',
      luas_status_uom_id: '6317d83e5f05cc92cf2d556f', // DONE
      status_produktivitas: '6317d8bf5f05cc92cf2d5574',
      data_pengelolaan: [] as any,
      historical_date: '',
      nomor_surat: '',
      pengguna: '',
      nama_pengguna: '',
      status_pengelolaan: '',
      luas_penggunaan: 0,
      upaya_pemanfaatan: '',
      luas_pemanfaatan: '',
      luas_pemanfaatan_uom_id: '6317e5795f05cc92cf2d5628', //DONE
      keterangan_pemanfaatan: '',
      nilai_buku: {
        currency_id: '6317d1eb5f05cc92cf2d553d', //DONE
        value: 0,
      },
      nilai_revaluasi: {
        currency_id: '6317d1eb5f05cc92cf2d553d', //DONE
        value: 0,
      },
      selectMap: '' as any,
      attachment: [] as any,
    });

    const dialogVisibleDoc = ref(false);

    watch(
      () => _.loadDocument,
      (first, second) => {
        console.log(
          'Watch props.selected function called with args:',
          first,
          second
        );
        forms.attachment = _.loadDocument;
        emit('arrayDoc', forms.attachment);
      }
    );

    return {
      t,
      images,
      forms,
      dialogVisibleDoc,
      loadingFile,
      doc,
      selectedFileDoc,
      counterDoc,
      getRootProps,
      getInputProps,
      isImgUrl,
      detectPath,
      documentName,
      tempFile,
      iconPdf,
      iconXlsx,
      iconDocx,
      iconRar,
      iconCsv,
      iconXml,
      ...rest,
    };
  },
});
