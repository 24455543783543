import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db8a4f3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-header" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { style: {"font-size":"30px"} }
const _hoisted_4 = { style: {"font-size":"18px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ActivityAssetHistoryTable = _resolveComponent("ActivityAssetHistoryTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_ApprovalHistoryTable = _resolveComponent("ApprovalHistoryTable")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.$props.open,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$props.open) = $event)),
    "destroy-on-close": true,
    width: "75%",
    top: "5vh",
    "show-close": true,
    onClosed: $setup.close
  }, {
    title: _withCtx(({ titleId, titleClass }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          id: titleId,
          class: _normalizeClass(titleClass)
        }, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(`${$setup.activeLabel} List`), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(`[${$setup.payload?.external_code}] - ${$setup.capitalize($setup.payload?.aset_name)}`), 1),
          _createVNode(_component_el_input, {
            class: "nosubmit",
            modelValue: $setup.searchParams,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchParams) = $event)),
            type: "text",
            placeholder: $setup.t('pageListAssets.modalHistory.search'),
            clearable: ""
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                default: _withCtx(() => [
                  _createVNode(_component_search)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ], 10, _hoisted_2)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        modelValue: $setup.activeName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.activeName) = $event)),
        type: "border-card",
        onTabClick: $setup.tabChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            name: "activity",
            label: $setup.t("pageListAssets.modalHistory.tabs.activityHistory")
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ActivityAssetHistoryTable, { search: $setup.searchParams }, null, 8, ["search"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_tab_pane, {
            name: "approval",
            label: $setup.t("pageListAssets.modalHistory.tabs.approvalHistory")
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ApprovalHistoryTable, { search: $setup.searchParams }, null, 8, ["search"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClosed"]))
}