import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15710052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = {
  class: "d-grid",
  style: {"justify-items":"center"}
}
const _hoisted_3 = { class: "delete-title text-center my-2" }
const _hoisted_4 = { class: "text-center text-break delete-detail" }
const _hoisted_5 = { class: "d-grid gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBold = _resolveComponent("CloseBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      "show-close": false,
      "close-on-click-modal": false,
      title: "",
      width: "20%"
    }, {
      title: _withCtx(() => [
        _createVNode(_component_el_icon, {
          class: "el-icon--right position-close",
          onClick: _ctx.Cancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CloseBold)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.detail && _ctx.detail(...args))),
            class: "btn btn-danger mx-4"
          }, _toDisplayString(_ctx.t('pageListAssets.validationPolygon.add_polygon')), 1),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.Cancel && _ctx.Cancel(...args))),
            class: "btn btn-outline-danger mx-4"
          }, _toDisplayString(_ctx.t('pageListAssets.validationPolygon.close')), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_inline_svg, { src: _ctx.needPolygon }, null, 8, ["src"])
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('pageListAssets.validationPolygon.title')), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('pageListAssets.validationPolygon.description')), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}