
import { defineComponent, inject, ref, Ref, nextTick, computed } from 'vue';
import LeafletMap from '@/components/leaflet/Leaflet.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Playground',
  components: {
    LeafletMap
  },
  setup() {
    const { t } = useI18n();
    const images = inject('$images');

    const addMapMarker = ref('') as any;
    const coordinate = ref({
      lat: '',
      long: '',
    }) as any;

    const tempCoordinate = ref('');
    const coor = ref('')
    const polylineTemp = ref([]) as any;
    const polygonTemp = ref([]) as any;

    const getPoint = () => {
      if (addMapMarker.value.pinMarker.lat != '') {
        let lat = addMapMarker.value.pinMarker.lat;
        let lng = addMapMarker.value.pinMarker.lng;
        coordinate.value = {
          lat: lat,
          lng: lng,
        };

        tempCoordinate.value = lat + ',' + lng;
        coor.value = tempCoordinate.value;
      }
      
      polygonTemp.value = addMapMarker.value.pinPolygon;
      polylineTemp.value = addMapMarker.value.pinPolyline;
    };


    const remainingPolygons = ref<number[][][]>(polygonTemp.value);

    const deletePolygon = (polygon: number[][], i) => {
      const index = polygonTemp.value.indexOf(polygon);
      polygonTemp.value.splice(index, i);
      if (totalWide.value.length === 1) {
        totalWide.value = []
      }

      if (index === 0) {
        totalWide.value.shift();
      } else {
        totalWide.value.splice(index, i)
      }
      addMapMarker.value.removeMarker(index, 'polygon');
    };

    const deletePolyline = (polygon: number[][], i) => {
      const index = polylineTemp.value.indexOf(polygon);
      polylineTemp.value.splice(index, i);
      if (totalLength.value.length === 1) {
        totalLength.value = []
      }

      if (index === 0) {
        totalLength.value.shift();
      } else {
        totalLength.value.splice(index, i)
      }
      console.log('index,', totalLength.value)
      addMapMarker.value.removeMarker(index, 'polyline');
    };

    const deletePoints = (mas) => {
      addMapMarker.value.removeMarker(mas, 'marker');
      addMapMarker.value = 
      tempCoordinate.value = ''
      coor.value = ''
    };

    const wideArea = ref('')
    const lengthPolyline = ref('')

    const totalWide : Ref<string[]> = ref([]);
    const totalLength : Ref<string[]> = ref([]);

    const getWideArea = (e) => {
      totalWide.value.push(e)
    }

    const getLengthLine = (e) => {
      totalLength.value.push(e)
    }

    const sumPolygon = computed(() => {
      let temp = ['']
      temp = totalWide.value
      return temp.reduce((accumulator, currentValue) => {
        const number = parseFloat(currentValue);
        return accumulator + number;
      }, 0);
    });

    const sumPolyline = computed(() => {
      let temp = ['']
      temp = totalLength.value
      return temp.reduce((accumulator, currentValue) => {
        const number = parseFloat(currentValue);
        return accumulator + number;
      }, 0);
    });

    

    const renderComponent = ref(true)

    const forceRenderer = () => {
      renderComponent.value = false
      nextTick(() => {
        renderComponent.value = true
      })
    }

    const copy = (type, value) => {
      if (type === 'marker') {
        const latLongText = `lat:${coordinate.value.lat}, long:${coordinate.value.lng}`;
        navigator.clipboard.writeText(latLongText).then(() => {
          ElMessage({
            offset: 700,
            showClose: true,
            message: t('playground.alertCopy'),
          })
        })
      } else if (type === 'polygon') {
        const convertedPolygons = polygonTemp.value.map(polygon => polygon.map(point => point.join(',')).join(',')).join(';')
        navigator.clipboard.writeText(`"${convertedPolygons}"`).then(() => {
          ElMessage({
            offset: 700,
            showClose: true,
            message: t('playground.alertCopy'),
          })
        })
      } else {
        const polylineCoordinates = polylineTemp.value.map(point => point.join(',')).join(';');
        navigator.clipboard.writeText(`"${polylineCoordinates}"`)
          .then(() => {
            ElMessage({
              offset: 700,
              showClose: true,
              message: t('playground.alertCopy'),
            })
          })
      }

    }

    const resetMap = () => {
      polygonTemp.value = []
      polylineTemp.value = []
      coor.value = ''
      totalWide.value = []
      totalLength.value = []
      forceRenderer()
    }

    return {
      images,
      t,
      getPoint,
      addMapMarker,
      polygonTemp,
      polylineTemp,
      remainingPolygons,
      deletePolygon,
      deletePolyline,
      coor,
      deletePoints,
      renderComponent,
      getWideArea,
      getLengthLine,
      wideArea,
      lengthPolyline,
      Search,
      copy,
      resetMap,
      sumPolygon,
      sumPolyline
    };
  },
});
