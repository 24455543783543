
import { defineComponent, inject, onMounted, ref } from 'vue';
import images from '@/assets/ts/images.js';
import { useI18n } from 'vue-i18n';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'List of Assets',
  data() {
    return {
      iconClose: images.closeChips,
      assets: [
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Rejected',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Pending',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Rejected',
        },
      ],
    };
  },
  setup(ctx) {
    const { t } = useI18n();
    const images = inject('$images');
    const store = useStore();
    const setSelected = ref('');

    function setTarget(value) {
      setSelected.value = value;
    }
    onMounted(() => {
      const element: HTMLElement = document.querySelector(
        '.reportContainer .nav li:first-child a'
      ) as HTMLElement;
      if (element) {
        element.click();
      }
      setCurrentPageBreadcrumbs('Daftar Aset', []);
    });
    return {
      images,
      t,
      setTarget,
    };
  },
});
