
import {
  defineComponent,
  inject,
  onMounted,
  onBeforeMount,
  ref,
  computed,
  watch,
} from 'vue';
import images from '@/assets/ts/images.js';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { ActionsAdminValidasi, MutationsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { useStore } from 'vuex';
import { toastError, toastSuccess } from '@/core/services/Alert';
import router from '@/router';
import _Pagination from 'element-plus/lib/el-pagination';
import imagesAsset from '@/assets/ts/images.js';
import {
  checkPermissionUpdate,
  tagDefineColor,
  statusValidasi,
dateFormat
} from '@/core/helpers/helps';
// import Error403 from '@/views/crafted/authentication/Error403.vue';
import HistoryAssetModal from '@/components/modals/list-asset/HistoryAssetModal.vue';
import RejectAssetModal from "@/components/modals/list-asset/admin-validasi/RejectAssetModal.vue";
import ApproveAssetModal from "@/components/modals/list-asset/admin-validasi/ApproveAssetModal.vue";
import ProposeValidation from "@/components/modals/list-asset/ProposeValidation.vue";
import FeedbackModal from "@/components/modals/list-asset/admin-validasi/FeedbackModal.vue";
import { ElLoading, ElNotification } from 'element-plus';

export default defineComponent({
  name: 'List of Assets',
  components: {
    // Error403,
    HistoryAssetModal,
    RejectAssetModal,
    ApproveAssetModal,
    // FeedbackModal,
    ProposeValidation
  },
  data() {
    return {
      iconClose: images.closeChips,
      cursorChanges: {
        cursor: 'pointer',
      },
    };
  },
  setup() {
    // const modalValidasi = ref(false);
    const modalValidasi = computed(() => store.getters.getRequestReviewModal);
    const askEdit = ref(false)
    const router = useRouter();
    const { t } = useI18n();
    const images = inject('$images');
    const btnMore = imagesAsset.buttonmore;
    const btnGreen = imagesAsset.btnGreen;
    const btnRed = imagesAsset.btnRed;
    const askEditIcon = imagesAsset.askedit
    const history = imagesAsset.history
    const validated = imagesAsset.validated
    const assetValidasi = imagesAsset.assetValidasi
    const store = useStore();
    const setSelected = ref('');
    const asetId = ref('');
    const listAssetDelete = ref({})
    const setRouting = ref(false);
    const book = ref('');
    const statusProduktifitas = ref('Produktif');
    const pengguna = ref('pengguna');
    const status_legalitas = ref('');
    const searchStatus = ref(false);
    const reverseSelection = ref(false);
    const peruntukan = ref('');
    const filterRef: any = ref({
      asset_type_codes: [],
      id_channels: [],
      id_provinces: [],
      legalitas_codes: [],
      approval_status: [],
    });
    const form = ref({
      name: '',
      tanah: '',
      lokasi: '',
    });
    const listAssets: any = computed(() => store.getters.getListAsset);
    const listRequestEdit: any = computed(() => store.getters.getReqEdit);
    const openHistoryModal = ref(computed(() => store.getters.ModalHistory));
    const openApprovalList = ref(computed(() => store.getters.ApprovalList));
    const openValidasiList = ref(computed(() => store.getters.ValidasiList));
    const feedbackStatus = ref(computed(() => store.getters.statusFeedback));
    const loading = ref(computed(() => store.getters.Loading));
    const statusVerification = ref(
      computed(() => store.getters.ApprovalStatusList)
    );
    const detailAset: any = ref({});
    const titlePage = ref(t('headingPage.list'));
    const subTitlePage = ref(t('pageListAssets.listRequestUpdate'));
    const selectAsset:any = ref([]);
    const asset_total = computed(() => {
      return store.getters.getAssetTotal;
    });

    const currentPage = ref(1)
    const pageSize = ref(10)
    const small = ref(false)
    const background = ref(false)
    const disabled = ref(false)

    /**
     * list aset dibuat sama, semua roles - untuk master
     * 
     */

     const pagination = computed(() => {
			return {
				current: store.getters.assetFilter.page,
				to: store.getters.assetFilter.limit * store.getters.assetFilter.page,
				...store.getters.assetFilter,
			};
		});


    const assetSearch = ref([{}]);
    const listChannel = ref([{ _id: '', name: '' }]);
    const textSearchAssets = computed(
      () => store.getters.searchTextSearchAssets
    );
    const statusUploadMasal = computed(
      () => store.getters.getStatusUploadMasal
    );

    const getListAsset = computed(() => {
      return store.getters.listOfAssets;
    });

    const assetFilter = computed(() => {
      return store.getters.assetFilter;
    });

    const getMetaFetchAssets = computed(() => {
      return store.getters.getMetaOfListAssets;
    });

    var payloadEditID = ref('') as any

    const feedbackUpdate = ref('')

    const askForEditDialog = (id) => {
      askEdit.value = true
      payloadEditID.value = id
    }

    const selectedId = ref('')

    const openModalRequestReview = (status) => {
      store.commit(MutationsAdminValidasi.SET_BULK_REQUESTREVIEW_MODAL, status)
    }

    const popUpValidation = (val) => {
      if (val.polygon !== 0) {
        selectedId.value = val._id
        openModalRequestReview(true)
      } else {
        assetId.value = val._id
        needPolygon.value = true
      }
    }

    const cancelAjukanValidasi = () => {
      store.commit(MutationsAdminValidasi.SET_BULK_REQUESTREVIEW_MODAL, false)
    }

    const ajukanValidasi = () => {
      let entity_id:any = [];
      if (selectAsset.value.length > 0) {
        selectAsset.value.map((val) => {
          entity_id.push(val._id);
        })
      }
      
      const payloadValidasi = {
        user_id : getProfile.value._id,
        entity_id: selectAsset.value.length > 0 ? entity_id.toString() :selectedId.value,
        action: 'ASSET.request_review',
        entity: 'ASSET_KBUMN',
        init_role: getProfile.value.role._id
      }
      if(modalValidasi.value) {
        if (entity_id.length > 0) {

          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          store.dispatch(ActionsAdminValidasi.POST_BULK_STATUS_ASSET, payloadValidasi)
          .then((res) => {
            openModalRequestReview(false)
            fetchData({
              page: 1,
            });
            /**
             * reset selected asset after finish request review
             */
            store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
              ...store.getters.PayloadAction,
              entity_id : ''
            })

            loading.close()
          })
        } else {
          store.dispatch(Actions.APPROVAL_ASSETS, payloadValidasi)
          .then((res) => {
            openModalRequestReview(false)
            fetchData({
              page: 1,
            });
          })
        }

        
      } else {
        openModalRequestReview(false)
      }
    }

    const requestEdit = () => {
      const payload = {
        user_id : getProfile.value._id,
        entity_id: payloadEditID.value,
        action: 'asset.request_edit_submit',
        entity: 'ASSET_KBUMN',
        init_role: getProfile.value.role._id,
        feedback: feedbackUpdate.value
      }

      store.dispatch(Actions.APPROVAL_ASSETS, payload)
      .then((res) => {
        toastSuccess(`berhasil mengubah permohonan update`);
        askEdit.value = false
        // router.push(`/assets/views/${payloadEditID.value}/informasi-assets#FSD`);
        fetchData({})
      })
    }

    /**
     *
     * @param letParams halaman yang akan dipanggil
     * @param idDel id row data yang didelete
     */
    const fetchData = (letParams: any) => {
      store.commit(Mutations.SET_ID_CHANNELS, filterRef.value.id_channels)
      listAssets.value = [];
      filterAproval(letParams);

      // let params = {
      //   asset_type_codes: filterRef.value.asset_type_codes.toString(),
      //   id_channels: filterRef.value.id_channels.toString(),
      //   id_provinces: filterRef.value.id_provinces.toString(),
      //   legalitas_codes: filterRef.value.legalitas_codes.toString(),
      //   approval_status: filterRef.value.approval_status.toString(),
      //   ...letParams,
      // };

      // store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, true);
      // store
      //   .dispatch(Actions.GET_LIST_ASSETS, params)
      //   .finally(() => {
      //     store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, false);
      //   });
    };

    const fetchChannel = () => {
      store
        .dispatch(Actions.GET_CHANNEL_LIST)
        .then((res) => {
          listChannel.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    /**
     *
     * @param payload id channel yang akan di mamping
     */
    const mapChannel = (payload: any) => {
      if (listChannel.value.length !== 1) {
        let channel = listChannel.value.filter((el) => el._id === payload);
        if (channel.length > 0) {
          return channel[0].name;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const mapStatusLegalitas = (payload: any) => {
      if (status_list.value.length !== 1) {
        let statuslegalitas = status_list.value.filter((el) => el.code === payload);
        if (statuslegalitas.length > 0) {
          return statuslegalitas[0].name;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const mapProvince = (payload: any) => {
      if (province_list.value.length !== 1) {
        let province = province_list.value.filter((el) => el._id === payload.province_id);
        if (province.length > 0) {
          return province[0].name;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const mapApproval = (payload: any) => {
      if (getApproval.value.length) {
        let appr = getApproval.value.filter((el) => el._id === ( payload.approval_status_id === undefined ? payload?.approval_status?._id.$oid : payload.approval_status_id));
        if (appr.length > 0) {
          return appr[0].description;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const nameStatusApproval = (id: string) => {
      switch (id) {
        case '6333b254c352da91999f17bb':
          return t('pageListAssets.approval.name.initial')
        case '631ed357f97fb56ec2fcc0f0':
          return t('pageListAssets.approval.name.verified')
        case '631ed34af97fb56ec2fcc0ee':
          return t('pageListAssets.approval.name.unverified')
        case '631ed33bf97fb56ec2fcc0ec':
          return t('pageListAssets.approval.name.invalid')
        case '631ed318f97fb56ec2fcc0ea':
          return t('pageListAssets.approval.name.waiting_verification')
        default:
          return t('pageListAssets.approval.name.waiting_validasi')
      }
    }

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });
    const isolation = computed(() => {
      return store.getters.getRole;
    });

    const switchRequest = computed(() => {
      return store.getters.getSwitch
    });

    const getUpdateAssetFlag = computed(() => {
      return store.getters.getUpdateAssetFlag
    });
    
    const getChannel = computed(() => {
      if (
        getProfile.value.role._id !== '' &&
        isolation.value.isolation_permissions &&
        isolation.value.isolation_permissions[0].is_active
      ) {
        const isolate =
          store.state.AuthModule.userProfile.tenants.length > 0 &&
          store.state.AuthModule.userProfile.tenants[0].workspaces.length > 0
            ? store.state.AuthModule.userProfile.tenants[0].workspaces[0]
                .channels
            : [];
        return isolate;
      } else {
        return store.getters.getListChannel;
      }
    });

    const category_list = computed(() => {
      return store.getters.getListCategory;
    });

    const province_list = computed(() => {
      return store.getters.getListProvince;
    });

    const status_list = computed(() => {
      return store.getters.getListLegal;
    });

    const getApproval = computed(() => {
      return store.getters.ApprovalStatusList
    })

    const filterAproval = (letParams) => {
      /**
       * inisiasi value for filterRef
       */
      // filterRef.value.approval_status = '';

      if (openApprovalList.value.status) {
        filterRef.value.approval_status = openApprovalList.value.validated ? '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea' : '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea,631ed357f97fb56ec2fcc0f0';
      }

      if (openValidasiList.value.status) {
        /**
         * request_review : _id status intial
         * validated : _id status waiting for validated
         * verfied : _id status waiting for verified
         */
        const filterMaster = {
          request_review : '6333b254c352da91999f17bb',
          validated : '631ed2f9f97fb56ec2fcc0e8',
          verified : '631ed318f97fb56ec2fcc0ea'
        }
        
        Object.keys(openValidasiList.value).forEach((key, index) => {
          if (filterMaster[key] && openValidasiList.value[key]) {
            filterRef.value.approval_status = filterMaster[key]
          }
        })
      }

      let params = {
        asset_type_codes: filterRef.value.asset_type_codes.toString(),
        id_channels: filterRef.value.id_channels.toString(),
        id_provinces: filterRef.value.id_provinces.toString(),
        legalitas_codes: filterRef.value.legalitas_codes.toString(),
        approval_status: filterRef.value.approval_status.toString(),
        ...letParams,
      };

      store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, true);
      store
        .dispatch(Actions.GET_LIST_ASSETS, params)
        .finally(() => {
          store.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, false);
        });
    }

    watch(statusUploadMasal, (currentState, prevCurrentState) => {
      if (currentState) {
        fetchData(
          {
            page: 1,
          }
        );
      }
    });

    watch(textSearchAssets, (currentState, prevCurrentState) => {
      if (currentState.search === '') {
        fetchData(
          {
            page: 1,
          }
        );
        currentPage.value = 1
        assetSearch.value = [];
        searchStatus.value = false;
      } else {
        fetchData(
          {
            page: 1,
            ...currentState
          }
        );
        currentPage.value = 1
      }
    });

    watch(openValidasiList, (currentState, prevCurrentState) => {
      /**
       * jika status request review sebelumnya true dan status saat ini false 
       * maka reload data asset
       */
      if (prevCurrentState.request_review && !currentState.status) {
        fetchData({
            page : 1,
          })
      }

      if (
        !currentState.status
      ) {
          setCurrentPageBreadcrumbs('headingPage.list', []);
          titlePage.value = 'headingPage.list';
          // filterRef.value.approval_status = '';
          // fetchData({
          //   page : 1,
          // })
        // }
      } else {
        currentPage.value = 1
        resetPage.value = true
        if (!currentState.rejected && !currentState.approved ) {
          if (currentState.status !== prevCurrentState.status) {
            fetchData({
              page : 1,
            });
          }
          
          if (currentState.validated !== prevCurrentState.validated) {
            fetchData({
              page : 1,
            });
          }

          if (currentState.verified !== prevCurrentState.verfied) {
            fetchData({
              page : 1,
            });
          }

          if (currentState.request_review !== prevCurrentState.request_review) {
            fetchData({
              page : 1,
            });
          }

          let heading = '';
          let subHeading = 'pageListAssets.listRequestUpdate';
          if (currentState.validated) {
            heading = 'headingPage.requestValidate'
          }

          if (currentState.verified) {
            heading = 'headingPage.requestVerify'
          }

          if (currentState.request_review) {
            heading = 'headingPage.requestReview'
            subHeading = 'subHeadingPage.requestReview'
          }
          // let heading = currentState.validated ? 'headingPage.requestValidate' : 'headingPage.requestVerify';
          setCurrentPageBreadcrumbs(heading, [
            { title: 'headingPage.list', path: '/assets/list' },
          ]);
          titlePage.value = heading;
          subTitlePage.value = subHeading;
        }
      }
    });

    watch(openHistoryModal, (cr, pv) => {
      if (!cr) {
        setRouting.value = false;
      }
    })

    const handleModalAdminValidasi = (payload: any) => {
      setRouting.value = true;
      store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
        ...store.getters.PayloadAction,
        entity_id : payload?.entity_id || store.getters.PayloadAction.entity_id
      })

      if (!payload.entity_id && store.getters.PayloadAction.entity_id === '') {
          ElNotification({
            title: openApprovalList.value.validated ? 'Validasi Aset' :  'Verifikasi Aset',
            message: 'Silahkan Pilih Minimal Satu Aset',
            type: 'error',
          })

          return false;
        }

      if (payload.type === 'requestUpdated' || payload.update_requested) {
        store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
          ...payload,
        });
      } else {
        if (payload.x) {
          store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
            status: false,
            validated: payload.x === 'val' ? true : false,
            verified: payload.x === 'ver' ? true : false,
            request_review: false,
            action: payload.action
          })
        }
        store
        .dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
          ...payload,
          status : true,
        })
      }
    };

    const handleModalHistory = (row: any) => {
      setRouting.value = true;

      store.dispatch(ActionsAdminValidasi.GET_APPROVAL_HISTORY, row._id)
      store.dispatch(ActionsAdminValidasi.GET_ACTITVITY_HISTORY, row)

      store
        .dispatch(ActionsAdminValidasi.OPEN_MODAL_HISTORY, !store.getters.ModalHistory)

      detailAset.value = row;
    };

    const handleClickRowAsset = (val) => {
      var routing = '';
      routing = val._id;
      if (checkPermissionUpdate('assetlist', 'assetlist.view.detail')) {
        if (setRouting.value === true) {
          routing = '';
        } else {
          const newTab = router.resolve(`/assets/views/${routing}/informasi-assets#FSD`);
          window.open(newTab.href, '_blank');
        }
      }
    };

    const handleSelectAsset = (selection:any, row:any) => {
      store.commit(Mutations.SET_SHOW_TOOLBAR, selection.length > 0 ? false : true)
      selectAsset.value = selection;
      
      let tmp: any = [];
      selection.map((row:any) => {
        tmp.push(row._id);
      })
      
      if (selection.length > 0) {
        store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
          ...store.getters.PayloadAction,
          entity_id : tmp.toString() 
        })
        if(selectAsset.value.length === 1) {
          store.commit(Mutations.SET_LAST_NAME_ASSET, selection[0])
        }
      } else {
        store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
          ...store.getters.PayloadAction,
          entity_id : ''
        })
      }
      
      setRouting.value = true;
    }

    const handleDeleteAset = ({ id, payload }) => {
      store
        .dispatch(Actions.DELETE_ASSET, {
          id : id,
          payload : payload
        })
        .then((response) => {
          toastSuccess(`${t('common.success')} ${t('common.delete')} `);
          fetchData(
            {
              page: 1,
              ...textSearchAssets.value,
            }
          );
        })
        .catch((err) => {
          toastError(`${t('common.failed')} ${t('common.delete')} `);
          setRouting.value = false;
        })
        .finally(() => {
          setRouting.value = false;
        });
    };

    function setTarget(value) {
      setSelected.value = value;
    }

    const asset_initial = computed(() => {
      return store.getters.getAssetInitial;
    });

    const reset_filter = computed(() => {
      return store.getters.getResetFilter;
    });
    
    const getUpdatedAsset = computed(() => {
      return store.getters.getUpdatedAsset;
    });

    const resetPage = ref(false)

    watch(getUpdatedAsset, (currentState, prevCurrentState) => {
      if (currentState) {
        currentPage.value = 1
        resetPage.value = true
        filterRef.value.approval_status = "631ed33bf97fb56ec2fcc0ec,631ed34af97fb56ec2fcc0ee,6333b254c352da91999f17bb"
      }
    });

    watch(asset_initial, (old, latest) => {
      if (asset_initial.value !== '') {
        currentPage.value = 1
        resetPage.value = true
        filterRef.value.approval_status = asset_initial.value
        fetchData({
          page : 1,
          approval_status : asset_initial.value
        });
        store.commit(Mutations.SET_ASSET_INITIAL, '')
      }
    })
    
    watch(reset_filter, (old, latest) => {
      if (reset_filter.value) {
        filterRef.value.approval_status = ''
        fetchData({
          page : 1,
          approval_status : ''
        });
        store.commit(Mutations.SET_ASSET_INITIAL, '')
      }
    })

    onBeforeMount(() => {
      // if (!checkAccess('assetlist')) {
      //     router.push({
      //     path: '/404',
      //     });
      // }
      if (store.getters.ApprovalList.status) {
        filterRef.value.approval_status = store.getters.ApprovalList.validated ? '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea' : '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea,631ed357f97fb56ec2fcc0f0';
        setCurrentPageBreadcrumbs('headingPage.requestUpdate', [
            { title: 'headingPage.list', path: '/assets/list' },
          ]);
        titlePage.value = 'headingPage.requestUpdate';
      } else if (store.getters.ValidasiList.status) {
        filterRef.value.approval_status = store.getters.ValidasiList.validated ? '631ed2f9f97fb56ec2fcc0e8' : '631ed318f97fb56ec2fcc0ea';
        setCurrentPageBreadcrumbs(store.getters.ValidasiList.validated ? 'headingPage.requestValidate' : 'headingPage.requestVerify', [
          { title: 'headingPage.list', path: '/assets/list' },
        ]);
        titlePage.value = store.getters.ValidasiList.validated ? 'headingPage.requestValidate' : 'headingPage.requestVerify';
      }

      fetchData(
          {
            page: 1,
          }
      );
      /**
       * dispatch filter list
       */
      // store.dispatch(Actions.GET_CHANNEL_LIST)
      store.dispatch(ActionsAdminValidasi.GET_APPROVAL_STATUS);

      store.dispatch(
        Actions.GET_ASSET_CATEGORY_LIST,
        '631577ec8c130d3fa0415fe8'
      );
      store.dispatch(Actions.GET_PROVINCE_LIST);
      store.dispatch(
        Actions.GET_STATUS_LEGAL_ASSETS,
        '6316769d8c130d3fa041611c'
      );

      fetchChannel();
      const element: HTMLElement = document.querySelector(
        '.reportContainer .nav li:first-child a'
      ) as HTMLElement;
      if (element) {
        element.click();
      }

      setCurrentPageBreadcrumbs('mainMenuTop.listAsset', []);
    });

    const handleSizeChange = (val: number) => {
      fetchData({
        limit : val,
        ...textSearchAssets.value,
      });
    }
    const handleCurrentChange = (val: number) => {
      fetchData({
        page : val,
        ...textSearchAssets.value,
      });
    }

    /**
     * jika validasi sebelumnya true, maka cancel seluruh proses pengajuan validasi
     */
    // watch(modalValidasi, (cr, pv) => {
    //   console.log(pv);
      
    //   if (pv.value) {
    //     cancelAjukanValidasi()
    //   }
    // })
    const assetId = ref('')
    const needPolygon = ref(false)
    const closePropose = (val) => {
      needPolygon.value = val
    }

    const allAsset = ref(computed(() => store.getters.assetsAll));

    watch(allAsset, (currentState, prevCurrentState) => {
      if (currentState) {
        resetPage.value = false
        currentPage.value = 1
      }
    });

    const handleIsRowCanSelect = (row, index) => {
      return statusValidasi(row.approval_status?.description, {
              requestStatus : openApprovalList.value.status,
              validateStatus : openValidasiList.value.status,
              validate : openValidasiList.value.validated,
              verified : openValidasiList.value.verified
            })
    }

    const clicked = (payload, params) => {
      if (params === true) {
        setRouting.value = true;
        asetId.value = payload?._id || payload;
        listAssetDelete.value = payload?._id && payload || {}
      } else {
        setRouting.value = false;
      }
    }

    const redirect = (id, params) => {
      if (params === true) {
        setRouting.value = true;
        router.push(`/assets/update/${id}/informasi-assets#FSD`);
      } else {
        setRouting.value = false;
      }
    }

    return {
      modalValidasi,
      currentPage,
      small,
      background,
      disabled,
      handleSizeChange,
      handleCurrentChange,
      pageSize,
      switchRequest,
      getListAsset,
      assetFilter,
      fetchData,
      handleClickRowAsset,
      form,
      listAssets,
      Actions,
      assetSearch,
      history,
      images,
      t,
      setTarget,
      statusProduktifitas,
      peruntukan,
      status_legalitas,
      pengguna,
      book,
      setRouting,
      asetId,
      handleDeleteAset,
      getMetaFetchAssets,
      pagination,
      askForEditDialog,
      popUpValidation,
      ajukanValidasi,
      assetValidasi,
      cancelAjukanValidasi,
      btnMore,
      btnGreen,
      btnRed,
      askEdit,
      validated,
      textSearchAssets,
      searchStatus,
      listChannel,
      mapChannel,
      mapStatusLegalitas,
      checkPermissionUpdate,
      requestEdit,
      feedbackUpdate,
      askEditIcon,
      getChannel,
      category_list,
      province_list,
      getApproval,
      mapProvince,
      mapApproval,
      nameStatusApproval,
      filterRef,
      status_list,
      tagDefineColor,
      statusVerification,
      handleModalHistory,
      openHistoryModal,
      detailAset,
      openApprovalList,
      dateFormat,
      openValidasiList,
      handleModalAdminValidasi,
      feedbackStatus,
      store,
      statusValidasi,
      handleSelectAsset,
      reverseSelection,
      titlePage,
      listRequestEdit,
      getUpdateAssetFlag,
      loading,
      getProfile,
      selectAsset,
      asset_total,
      listAssetDelete,
      subTitlePage,
      needPolygon,
      closePropose,
      assetId,
      asset_initial,
      getUpdatedAsset,
      allAsset,
      handleIsRowCanSelect,
      clicked,
      redirect
    };
  }
});
