
  import {
    defineComponent,
    inject,
    ref,
    reactive,
    computed,
    onMounted,
    watch,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import imagesAsset from '@/assets/ts/images.js';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import moment from "moment";
  import LeafletMap from '@/components/leaflet/Leaflet.vue';
  import { useRouter } from 'vue-router';
  import ImageUpload from '@/components/upload/ImageUpload.vue';
  import FileUpload from '@/components/upload/FileUpload.vue';
  import { ElMessage } from 'element-plus';
  import type { ElForm } from 'element-plus';
  import TableDataNilai from '@/components/table/TableDataNilai.vue'
  import _ from 'lodash';
  import { OpenStreetMapProvider } from 'leaflet-geosearch';

  interface RuleForm {
    selectYear: string,
    currencyBook: string,
    currencyRevaluation: string,
    tempNilaiBuku: string,
    tempNilaiRevaluasi: string,
    type: string,
    id: number
  }
  
  export default defineComponent({
    name: 'List of Assets',
    components: { LeafletMap, ImageUpload, FileUpload, TableDataNilai },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
      const keterangan = ref([]);
      const dataBook = ref([]);
      const dataRevaluation = ref([]);
      const store = useStore();
      const images = inject('$images');
  
      const mapPoint = imagesAsset.addPoint;
      const polygon = imagesAsset.polygon;
      const polyline = imagesAsset.polyline;
      const pointmapInput = imagesAsset.pointmapInput;
      const polygonInput = imagesAsset.polygonInput;
      const polylineInput = imagesAsset.polylineInput;
      const trash = imagesAsset.trash;
      const btnGreen = imagesAsset.btnGreen;
      const btnClose = imagesAsset.close;
      const btnCancel = imagesAsset.cancel;
      const assetValidasi = imagesAsset.assetValidasi;
      const btnRed = imagesAsset.btnRed;
      const btnEdit = imagesAsset.btnEdit;
      const btnDelete = imagesAsset.btnDelete;
      const polylineTemp = ref([]) as any;
      const polygonTemp = ref([]) as any;
  
      const currencyRevaluation = ref('');
      const currencyBook = ref('');
  
      const addProduktif = ref(true);
  
      const checkClear = (id) => {
        if (id === '631676fe8c130d3fa041611f') {
          forms.keterangan_aset = [];
        }
      };
  
      const modalValidasi = ref(false);

      const delay = 500; 
      const isButtonDisabled = ref(false);

      const handleDoubleClick = (customData: string, event: MouseEvent) => {

        if (!isButtonDisabled.value) {
          if (customData === 'propose') {
            AjukanValidasi(formRef.value, event);
          } else {
            submitAssets(formRef.value, event)
          }
          isButtonDisabled.value = true;
          setTimeout(() => {
            isButtonDisabled.value = false;
          }, delay);
        }
      };

  
      const AjukanValidasi = (formEl: InstanceType<typeof ElForm> | undefined, event: MouseEvent) => {
        event.preventDefault();
        if (!formEl) return;
  
        formEl.validate((valid, fields) => {
          submitButton.value?.setAttribute('data-kt-indicator', 'on');
          if (valid && tempNilai.length > 0 && tempRevaluasi.length > 0) {
            modalValidasi.value = true;
          } else {
            alertDataNilai.value = true;
            alertDataNilaiRevaluasi.value = true;
            ElMessage({
              message: 'Masih terdapat field yang kosong atau isian tidak sesuai, Silahkan Cek Kembali',
              type: 'error',
            });
          }
          submitButton.value?.setAttribute('data-kt-indicator', 'off');
        })
      };
  
      let tempArrPhoto = reactive({}) as any;
      let tempArrDoc = reactive([]);
  
      const getArrayPhoto = (val) => {
        tempArrPhoto = val;
      };
  
      const getArrayDoc = (val) => {
        tempArrDoc = val;
      };
  
      const add = () => {
        addProduktif.value = true;
        forms.nomor_surat = '';
        forms.pengguna = '';
        forms.nama_pengguna = '';
        forms.status_pengelolaan = '';
        forms.luas_penggunaan = '';
      };
  
      let idKelola = 0;
  
      const actProduktif = () => {
        if (forms.nomor_surat !== '' && forms.pengguna !== '' && forms.nama_pengguna !== '' && forms.status_pengelolaan !== '' && forms.luas_penggunaan !== '') {
          addProduktif.value = false;
          forms.data_pengelolaan.push({
            id: idKelola++,
            nomor_surat: forms.nomor_surat,
            pengguna: forms.pengguna,
            nama_pengguna: forms.nama_pengguna,
            status_pengelolaan: forms.status_pengelolaan,
            luas_penggunaan: forms.luas_penggunaan,
            luas_penggunaan_uom_id: '6317e0be5f05cc92cf2d5601',
          });
          forms.nomor_surat = '';
          forms.pengguna = '';
          forms.nama_pengguna = '';
          forms.status_pengelolaan = '';
          forms.luas_penggunaan = '';
        } else {
          ElMessage({
              message: t('detailAssets.validationProductif'),
              type: 'warning',
            });
        }
      };
  
      const data_pengelolaan = ref({
        nomor_surat: 0,
        pengguna: '',
        nama_pengguna: '',
        status_pengelolaan: '',
        luas_penggunaan: '',
        luas_penggunaan_uom_id: '6317e0be5f05cc92cf2d5601',
      });
  
      const editPengelolaan = ref(false);
  
      const editManage = (obj, id) => {
        idPengelolaan.value = id;
        editPengelolaan.value = true;
        forms.nomor_surat = obj.nomor_surat;
        forms.pengguna = obj.pengguna;
        forms.nama_pengguna = obj.nama_pengguna;
        forms.status_pengelolaan = obj.status_pengelolaan;
        forms.luas_penggunaan = obj.luas_penggunaan;
      };
  
      const idPengelolaan = ref(0);
      const actEditPengelolaan = () => {
        const findObj = forms.data_pengelolaan.find((o, i) => {
          if (o.id === idPengelolaan.value) {
            forms.data_pengelolaan[i] = {
              nomor_surat: forms.nomor_surat,
              pengguna: forms.pengguna,
              nama_pengguna: forms.nama_pengguna,
              status_pengelolaan: forms.status_pengelolaan,
              luas_penggunaan: forms.luas_penggunaan,
              luas_penggunaan_uom_id: '6317e0be5f05cc92cf2d5601',
            };
          }
        });
        editPengelolaan.value = false;
      };
  
      const delPengelolaan = (index) => {
        forms.data_pengelolaan.splice(forms.data_pengelolaan.indexOf(index), 1);
      };
  
      const forms = reactive({
        name: '',
        address: '',
        external_code: '',
        acquisition_date: '',
        acquisition_value_rp: 0,
        acquisition_value_usd: 0,
        acquisition_status: '',
        type: '',
        channel_id: '',
        province_id: '',
        regency_id: '',
        district_id: '',
        village_id: '',
        coor: '',
        coordinate: {
          lat: '-6.953305',
          long: '107.593422',
        },
        atas_nama: '',
        start_date_certificate: '',
        end_date_certificate: '',
        document_code: '',
        document_code_support: '',
        issued_by: '',
        luas_total: '',
        luas_total_uom_id: '63168fa18c130d3fa04161da', // done
        surat_ukur: '',
        status_tanah: '',
        status_legalitas: '631676fe8c130d3fa041611f',
        keterangan_aset: [],
        peruntukan: '',
        luas_status: '',
        luas_status_uom_id: '6317d83e5f05cc92cf2d556f', // DONE
        status_produktivitas: '6317d8bf5f05cc92cf2d5574',
        data_pengelolaan: [] as any,
        historical_date: '',
        nomor_surat: '',
        pengguna: '',
        nama_pengguna: '',
        status_pengelolaan: '',
        luas_penggunaan: '',
        upaya_pemanfaatan: '',
        array_upaya_pemanfaatan: [] as any,
        luas_pemanfaatan: '',
        luas_pemanfaatan_uom_id: '6317e5795f05cc92cf2d5628', //DONE
        keterangan_pemanfaatan: '',
        nilai_buku: {
          currency_id: '6317d1eb5f05cc92cf2d553d', //DONE
          value: 0,
        },
        nilai_revaluasi: {
          currency_id: '6317d1eb5f05cc92cf2d553d', //DONE
          value: 0,
        },
        selectMap: '' as any,
        attachment: [] as any,
      });
  
      const upayaMandatory = computed(() => {
        return forms.upaya_pemanfaatan === '6317d8ca5f05cc92cf2d5576'
          ? true
          : false;
      });
  
      const dateMandatory = computed(() => {
        return forms.status_tanah === '6316898e8c130d3fa0416189' ||
          forms.status_tanah === '6316899a8c130d3fa041618b'
          ? true
          : false;
      });

      const validCoor = computed(() => {
        return /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(tempCoordinate.value)
      });

      const formRef = ref<InstanceType<typeof ElForm>>();
      const formRefNilai = ref<InstanceType<typeof ElForm>>();
      const rulesDataNilai = reactive({
        selectYear: [
          {
            required: true,
            message: t('dataValueAssets.notice.year'),
            trigger: 'change',
          },
        ],
        currencyBook: [
          {
            required: true,
            message: t('dataValueAssets.notice.currency'),
            trigger: 'change',
          },
        ],
        currencyRevaluation: [
          {
            required: true,
            message: t('dataValueAssets.notice.currency'),
            trigger: 'change',
          },
        ],
        tempNilaiBuku: [
          {
            required: true,
            message: t('dataValueAssets.notice.book_value'),
            trigger: 'blur',
          },
          { pattern: /^\d+$/, message: t('dataValueAssets.min_book'), trigger: 'blur' },
        ],
        tempNilaiRevaluasi: [
          {
            required: true,
            message:  t('dataValueAssets.notice.revaluation_value'),
            trigger: 'blur',
          },
          { pattern: /^\d+$/, message: t('dataValueAssets.min_rev'), trigger: 'blur' },
        ],
      })

      const rules = reactive({
        channel_id: [
          { required: true, message: 'Please select BUMN', trigger: 'change' },
        ],
        type: [
          {
            required: true,
            message: 'Please select type of assets',
            trigger: 'change',
          },
        ],
        name: [
        {
          required: true,
          message: 'Please input name of asset',
          trigger: 'blur',
        },
        {
          pattern: /^[^<>?!]*$/,
          message: 'character <>?!; in not allowed',
        }
      ],
        external_code: [
          {
            required: true,
            message: 'Please input nomor surat of asset',
            trigger: 'blur',
          },
        ],
        acquisition_date: [
          {
            type: 'date',
            required: true,
            message: 'Please pick a acquisition date',
            trigger: 'change',
          },
        ],
        acquisition_status: [
          {
            required: true,
            message: 'Please select acquisition status',
            trigger: 'change',
          },
        ],
        acquisition_value_rp: [
          {
            required: true,
            message: 'Please input acquisition value',
            trigger: 'blur',
          },
        ],
        upaya_pemanfaatan: [
          {
            required: true,
            message: 'Please select upaya pemanfaatan',
            trigger: 'change',
          },
        ],
        province_id: [
          {
            required: true,
            message: 'Please select province of assets',
            trigger: 'change',
          },
        ],
        regency_id: [
          {
            required: true,
            message: 'Please select regency of assets',
            trigger: 'change',
          },
        ],
        village_id: [
          {
            required: true,
            message: 'Please select village of assets',
            trigger: 'change',
          },
        ],
        district_id: [
          {
            required: true,
            message: 'Please select district of assets',
            trigger: 'change',
          },
        ],
        coor: [
          {
            required: true,
            message: 'Please input coordinate value',
            trigger: 'blur',
          },
        ],
        status_tanah: [
          {
            required: true,
            message: 'Please select status tanah',
            trigger: 'change',
          },
        ],
        atas_nama: [
          {
            required: true,
            message: 'Please select atas nama',
            trigger: 'change',
          },
        ],
        start_date_certificate: [
          {
            type: 'date',
            required: true,
            message: 'Please pick a start date certificate',
            trigger: 'change',
          },
        ],
        end_date_certificate: [
          {
            type: 'date',
            required: dateMandatory,
            message: 'Please pick a end date certificate',
            trigger: 'change',
          },
        ],
        luas_total: [
          { required: true, message: 'Please input luas total', trigger: 'blur' },
        ],
        surat_ukur: [
          { required: true, message: 'Please input surat ukur', trigger: 'blur' },
        ],
        status_legalitas: [
          {
            required: true,
            message: 'Please select status legalitas',
            trigger: 'change',
          },
        ],
        peruntukan: [
          { required: true, message: 'Please input peruntukan', trigger: 'blur' },
        ],
        status_produktivitas: [
          {
            required: true,
            message: 'Please select status produktivitas',
            trigger: 'change',
          },
        ],
        document_code: [
          {
            required: true,
            message: 'Please input document code',
            trigger: 'blur',
          },
        ],
        issued_by: [
          { required: true, message: 'Please input issued by', trigger: 'blur' },
        ],
        nomor_surat: [
          {
            required: true,
            message: 'Please input nomor surat',
            trigger: 'blur',
          },
        ],
        pengguna: [
          { required: true, message: 'Please input pengguna', trigger: 'change' },
        ],
        nama_pengguna: [
          {
            required: true,
            message: 'Please input nama pengguna',
            trigger: 'blur',
          },
        ],
        luas_penggunaan: [
          {
            required: true,
            message: 'Please input luas pengguna',
            trigger: 'blur',
          },
        ],
        luas_pemanfaatan: [
          {
            required: true,
            message: 'Please input luas pemanfaatan',
            trigger: 'blur',
          },
        ],
        status_pengelolaan: [
          {
            required: true,
            message: 'Please input status pengelolaan',
            trigger: 'change',
          },
        ],
      });
  
      const submitButton = ref<HTMLElement | null>(null);
  
      const drawerNilai = ref(false);
  
      const getRegencyById = (id) => {
        store.dispatch(Actions.GET_REGENCY_BY_PROV_LIST, id);
      };
  
      const getDistrictById = (id) => {
        store.dispatch(Actions.GET_DISTRICT_BY_REGENCY_LIST, id);
      };
  
      const getVillageById = (id) => {
        store.dispatch(Actions.GET_VILLAGE_BY_DISTRICT_LIST, id);
      };
  
      const closeDrawer = () => {
        drawerNilai.value = false;
      };
  
      const dialogVisibleDoc = ref(false);
      const tempCoordinate = ref('');
      const pinCoordinate = ref('');
  
      const namePengguna = (val) => {
        const temp = getListPengguna.value.filter(function (x) {
          return x._id === val;
        });
        return temp[0];
      };
  
      const namePengelolaan = (val) => {
        const temp = getListPengelolaan.value.filter(function (x) {
          return x._id === val;
        });
        return temp[0];
      };

      const namaUpaya = (val) => {
        const temp = getListUpayaPemanfaatan.value.filter(function (x) {
          return x._id === val
        })
        return temp[0]
      }
  
      const province = ref('');
      const regency = ref('');
      const district = ref('');
      const village = ref('');
      const channel = ref('');
      const street = ref('');
      const tempData = ref([]);
      const disableButton = ref(false);

      const show_form_upata = ref(true)

      const disable_add_efforts = computed(() => {
        if (forms.upaya_pemanfaatan !== '' && forms.luas_pemanfaatan !== '') {
          return false
        } else {
          return true
        }
      });

      let id_upaya = 0;

      const add_efforts = () => {
        show_form_upata.value = false
        forms.array_upaya_pemanfaatan.push({
          "id": id_upaya++,
          "upaya_pemanfaatan": forms.upaya_pemanfaatan,
          "luas_pemanfaatan": forms.luas_pemanfaatan,
          "luas_pemanfaatan_uom_id": "6317e5795f05cc92cf2d5628",
          "keterangan_pemanfaatan": forms.keterangan_pemanfaatan
        });
        forms.upaya_pemanfaatan = ''
        forms.luas_pemanfaatan = ''
        forms.keterangan_pemanfaatan = ''
      }

      const add_field_effort = () => {
        show_form_upata.value = true
        forms.upaya_pemanfaatan = ''
        forms.luas_pemanfaatan = ''
        forms.keterangan_pemanfaatan = ''
      }

      const delUpaya = (index) => {
        forms.array_upaya_pemanfaatan.splice(forms.array_upaya_pemanfaatan.indexOf(index), 1);
      };

      const editUpaya = ref(false);
  
      const editManageUpaya = (obj, id) => {
        idUpaya.value = id;
        editUpaya.value = true;
        forms.upaya_pemanfaatan = obj.upaya_pemanfaatan
        forms.luas_pemanfaatan = obj.luas_pemanfaatan
        forms.keterangan_pemanfaatan = obj.keterangan_pemanfaatan
      };
  
      const idUpaya = ref(0);
      const actEditUpaya = () => {
        const findObj = forms.array_upaya_pemanfaatan.find((o, i) => {
          if (o.id === idUpaya.value) {
            forms.array_upaya_pemanfaatan[i] = {
              "upaya_pemanfaatan": forms.upaya_pemanfaatan,
              "luas_pemanfaatan": forms.luas_pemanfaatan,
              "luas_pemanfaatan_uom_id": "6317e5795f05cc92cf2d5628",
              "keterangan_pemanfaatan": forms.keterangan_pemanfaatan
            };
          }
        });
        editUpaya.value = false;
      };
  
      const getProvince = computed(() => {
        return store.getters.getListProvince;
      });
  
      const formatPrice = (value) => {
        let val = (value / 1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      };
  
      const selectYear = ref('');
  
      const years = computed(() => {
        const currentYear = new Date().getFullYear();
        return Array.from(
          { length: currentYear - 1900 + 1 },
          (value, index) => 1900 + index
        );
      });

      const ruleForm = reactive<RuleForm>({
        selectYear: '',
        currencyBook: '',
        currencyRevaluation: '',
        tempNilaiBuku: '',
        tempNilaiRevaluasi: '',
        type: '',
        id: 0
      })

  
      const isolation = computed(() => {
        return store.getters.getRole;
      });
  
      const getRegency = computed(() => {
        return store.getters.getListRegency;
      });
  
      const getDistrict = computed(() => {
        return store.getters.getListDistrict;
      });
  
      const getVillage = computed(() => {
        return store.getters.getListVillage;
      });
  
      const getProfile = computed(() => {
        return store.getters.getUserProfile;
      });
  
      const getChannel = computed(() => {
        if (
          getProfile.value.role._id !== '' &&
          isolation.value.isolation_permissions &&
          isolation.value.isolation_permissions[0].is_active
        ) {
          let isolate =
            store.state.AuthModule.userProfile.tenants.length > 0 &&
            store.state.AuthModule.userProfile.tenants[0].workspaces.length > 0
              ? store.state.AuthModule.userProfile.tenants[0].workspaces[0]
                  .channels
              : [];
  
          return isolate;
        } else {
          return store.getters.getListChannel;
        }
      });
  
      const disableDate = (time: Date) => {
        return moment(time).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD");
      };
  
      const getJenisAset = computed(() => {
        return store.getters.getListCategory;
      });
  
      const getLegalStatus = computed(() => {
        return store.getters.getListLegal;
      });
  
      const getStatusTanah = computed(() => {
        return store.getters.getListStatusTanah;
      });
  
      const getStatusPerolehan = computed(() => {
        return store.getters.getStatusPerolehan;
      });
  
      const getKeteranganAset = computed(() => {
        return store.getters.getKeteranganAset;
      });
  
      const getListProduktivitas = computed(() => {
        return store.getters.getListProduktivitas;
      });
  
      const getListPengguna = computed(() => {
        return store.getters.getListPengguna;
      });
  
      const getListPengelolaan = computed(() => {
        return store.getters.getListPengelolaan;
      });
  
      const getListUpayaPemanfaatan = computed(() => {
        return store.getters.getListUpayaPemanfaatan;
      });
  
      const delListAssetValue = (index, type) => {
        if (type === 'Nilai Buku') {
          tempNilai.splice(tempNilai.indexOf(index), 1);
        } else {
          tempRevaluasi.splice(tempRevaluasi.indexOf(index), 1);
        }
      };
  
      const disabledForm = ref(0);
      const flagId = ref('');
      const disableYear = ref(false);
  
      const actionDataValue = (params) => {
        if(params.type === 'Nilai Buku' || params.type === 'Book Value') {
          openEditDataNilai(params.data)
        } else {
          openEditDataRevaluasi(params.data)
        }
      }
  
      const deleteDataValue = (params) => {
        delListAssetValue(params.data, params.type)
      }
  
      const openEditDataNilai = (obj) => {
        disableYear.value = true;
        flagId.value = obj.id;
        disabledForm.value = 1; // 0 show all data, 1. show data nilai buku and else revaluasi
        ruleForm.selectYear = obj.year;
        ruleForm.currencyBook = obj.currency_id;
        ruleForm.tempNilaiBuku = obj.value;
        drawerNilai.value = true;
      };
  
      const openEditDataRevaluasi = (obj) => {
        disableYear.value = true;
        flagId.value = obj.id;
        disabledForm.value = 2; // 0 show all data, 2. show data nilai buku and else revaluasi
        ruleForm.selectYear = obj.year;
        ruleForm.currencyRevaluation = obj.currency_id;
        ruleForm.tempNilaiRevaluasi = obj.value;
        drawerNilai.value = true;
      };
  
      var alertDataNilai = ref(false);
      var alertDataNilaiRevaluasi = ref(false);
  
      const editDataNilai = (formEl: InstanceType<typeof ElForm> | undefined) => {
        if (!formEl) return;
        formEl.validate((valid, fields) => {
          submitButton.value?.setAttribute('data-kt-indicator', 'on');
          if (valid) {
            if (disabledForm.value === 1) {
              drawerNilai.value = false;
              tempNilai.find((o, i) => {
                if (o.id === flagId.value) {
                  tempNilai[i] = {
                    currency_id: ruleForm.currencyBook,
                    value: ruleForm.tempNilaiBuku,
                    year: ruleForm.selectYear,
                    type: "Nilai Buku",
                    id: o.id
                  };
                }
              });
            } else if (disabledForm.value === 2) {
              drawerNilai.value = false;
              tempRevaluasi.find((o, i) => {
                if (o.id === flagId.value) {
                  tempRevaluasi[i] = {
                    currency_id: ruleForm.currencyRevaluation,
                    value: ruleForm.tempNilaiRevaluasi,
                    year: ruleForm.selectYear,
                    type: 'Nilai Revaluasi',
                    id: o.id
                  };
                }
              });
            }
          } else {
            ElMessage({
              message: t('dataValueAssets.alert'),
              type: 'error',
            });
          }
          submitButton.value?.setAttribute('data-kt-indicator', 'off');
        })
      };
  
      let numList = 1;
      let numListRevaluation = 1;
  
      const openDrawer = () => {
        disableYear.value = false;
        disabledForm.value = 0;
        drawerNilai.value = true;
        ruleForm.tempNilaiBuku = '';
        ruleForm.tempNilaiRevaluasi = '';
        ruleForm.selectYear = '';
        ruleForm.currencyBook = '6315cf948c130d3fa04160fe';
        ruleForm.currencyRevaluation = '6315cf948c130d3fa04160fe';
      };
      let tempNilai = reactive([]) as any;
      let tempRevaluasi = reactive([]) as any;
  
      const pushNilaiData = (formEl: InstanceType<typeof ElForm> | undefined) => {
        if (!formEl) return;
  
        formEl.validate((valid, fields) => {
          submitButton.value?.setAttribute('data-kt-indicator', 'on');
          if (valid) {
            tempNilai.push({
              id: numList++,
              year: ruleForm.selectYear,
              currency_id: ruleForm.currencyBook,
              value: ruleForm.tempNilaiBuku,
              type: 'Nilai Buku'
            } as never);

            drawerNilai.value = false;
            alertDataNilai.value = false;

            tempRevaluasi.push({
              id: numListRevaluation++,
              year: ruleForm.selectYear,
              currency_id: ruleForm.currencyRevaluation,
              value: ruleForm.tempNilaiRevaluasi,
              type: 'Nilai Revaluasi'
            } as never);

            drawerNilai.value = false;
            alertDataNilaiRevaluasi.value = false;
          } else {
            ElMessage({
              message: t('dataValueAssets.alert'),
              type: 'error',
            });
          }
          submitButton.value?.setAttribute('data-kt-indicator', 'off');
        })
      };
  
      const NumbersOnly = (e) => {
        const char = String.fromCharCode(e.keyCode);
        if (/^\d+$/.test(char)) return true;
        else e.preventDefault();
      };
  
      const formatNumber = (num) => {
        return Number(num).toLocaleString();
      };
  
      const parseNumber = (text) => {
        return Number(text.replace('$', '').replace(/,/g, ''));
      };
  
      const tempNilaiRevaluasi = computed({
        get() {
          return formatNumber(forms.nilai_revaluasi.value);
        },
        set(newValue) {
          forms.nilai_revaluasi.value = parseNumber(newValue);
        },
      });
  
      const tempNilaiBuku = computed({
        get() {
          return formatNumber(forms.nilai_buku.value);
        },
        set(newValue) {
          forms.nilai_buku.value = parseNumber(newValue);
        },
      });
  
      const nilaiStatusPerolehan = computed({
        get() {
          return formatNumber(forms.acquisition_value_rp);
        },
        set(newValue) {
          forms.acquisition_value_rp = parseNumber(newValue);
        },
      });
  
      const submitAssets = (formEl: InstanceType<typeof ElForm> | undefined, event: MouseEvent) => {
        event.preventDefault();

        press_manage.value = false
        press_manage_idle.value = false
        forms.coor = tempCoordinate.value;
        const temp = tempCoordinate.value.split(',');
        forms.coordinate.lat = temp[0];
        forms.coordinate.long = temp[1];
  
        var polygonPin = [] as any;
        if (polygonTemp.value.length > 0) {
          polygonTemp.value.forEach((x: any) => {
            var tempolygonPin = [] as any;
            if (Array.isArray(x)) {
              x.forEach((y) => {
                tempolygonPin.push({ lat: y[0], long: y[1] });
              });
              polygonPin.push(tempolygonPin);
            }
          });
        }
  
        var polylinePin = [] as any;
        if (polylineTemp.value.length > 0) {
          polylineTemp.value.forEach((x: any) => {
            var tempolylinePin = [] as any;
            if (Array.isArray(x)) {
              x.forEach((y) => {
                tempolylinePin.push({ lat: y[0], long: y[1] });
              });
              polylinePin.push(tempolylinePin);
            }
          });
        }
  
        if (!formEl) return;
  
        formEl.validate((valid, fields) => {
          submitButton.value?.setAttribute('data-kt-indicator', 'on');
          if (valid && tempNilai.length > 0 && tempRevaluasi.length > 0 && validCoor.value && polygonTemp.value.length > 0) {
            disableButton.value = true
            type Payload = {
              [key: string]: any;
            };
  
            if (tempArrPhoto.length) {
              tempArrPhoto.map((photo) => {
                forms.attachment.push(photo);
              });
            }
  
            if (tempArrDoc) {
              tempArrDoc.map((doc) => {
                forms.attachment.push(doc);
              });
            }
  
            const payload: Payload = {
              name: forms.name,
              address: forms.address,
              external_code: forms.external_code,
              acquisition_date: forms.acquisition_date,
              acquisition_value_rp: Number(forms.acquisition_value_rp),
              acquisition_value_usd: 2,
              acquisition_status: forms.acquisition_status,
              type: forms.type,
              channel_id: forms.channel_id,
              province_id: forms.province_id,
              regency_id: forms.regency_id,
              district_id: forms.district_id,
              village_id: forms.village_id,
              coordinate: forms.coordinate,
              polyline: polylinePin,
              polygon: polygonPin,
              atas_nama: forms.atas_nama,
              document_code: forms.document_code,
              document_code_support: forms.document_code_support,
              issued_by: forms.issued_by,
              luas_total: Number(forms.luas_total),
              luas_total_uom_id: '63168fa18c130d3fa04161da',
              surat_ukur: forms.surat_ukur,
              status_tanah: forms.status_tanah,
              status_legalitas: forms.status_legalitas,
              keterangan_aset: forms.keterangan_aset,
              peruntukan: forms.peruntukan,
              luas_status: Number(forms.luas_status),
              luas_status_uom_id: '6317d83e5f05cc92cf2d556f',
              status_produktivitas: forms.status_produktivitas,
              data_pengelolaan: forms.data_pengelolaan,
              historical_date: forms.acquisition_date,
              nilai_buku: tempNilai,
              nilai_revaluasi: tempRevaluasi,
              attachment: forms.attachment,
            };
  
            type PayloadIdle = {
              [key: string]: any;
            };
  
            const payloadIdle: PayloadIdle = {
              name: forms.name,
              external_code: forms.external_code,
              acquisition_date: forms.acquisition_date,
              acquisition_value_rp: Number(forms.acquisition_value_rp),
              acquisition_value_usd: 2,
              acquisition_status: forms.acquisition_status,
              type: forms.type,
              channel_id: forms.channel_id,
              province_id: forms.province_id,
              regency_id: forms.regency_id,
              district_id: forms.district_id,
              village_id: forms.village_id,
              coordinate: forms.coordinate,
              polyline: polylinePin,
              polygon: polygonPin,
              atas_nama: forms.atas_nama,
              document_code: forms.document_code,
              document_code_support: forms.document_code_support,
              issued_by: forms.issued_by,
              luas_total: Number(forms.luas_total),
              luas_total_uom_id: '63168fa18c130d3fa04161da',
              surat_ukur: forms.surat_ukur,
              status_tanah: forms.status_tanah,
              status_legalitas: forms.status_legalitas,
              keterangan_aset: forms.keterangan_aset,
              peruntukan: forms.peruntukan,
              luas_status: Number(forms.luas_status),
              luas_status_uom_id: '6317d83e5f05cc92cf2d556f',
              status_produktivitas: forms.status_produktivitas,
              upaya_pemanfaatan: forms.array_upaya_pemanfaatan,
              luas_pemanfaatan: Number(forms.luas_pemanfaatan),
              luas_pemanfaatan_uom_id: '6317e5975f05cc92cf2d562a',
              keterangan_pemanfaatan: forms.keterangan_pemanfaatan,
              nilai_buku: tempNilai,
              nilai_revaluasi: tempRevaluasi,
              attachment: forms.attachment,
            };
  
            if (forms.end_date_certificate !== '') {
              payload.end_date_certificate = forms.end_date_certificate;
              payloadIdle.end_date_certificate = forms.end_date_certificate;
            }
  
            if (forms.start_date_certificate !== '') {
              payload.start_date_certificate = forms.start_date_certificate;
              payloadIdle.start_date_certificate = forms.start_date_certificate;
            }
  
            if (forms.status_produktivitas === '6317d8bf5f05cc92cf2d5574') {
              if (forms.data_pengelolaan.length > 0 && !addProduktif.value) {
                store.dispatch(Actions.CREATE_ASSETS, payload)
                .then((res) => {
                  const payloadValidasi = {
                    user_id : getProfile.value._id,
                    entity_id: res.data._id,
                    action: 'asset.request_review',
                    entity: 'ASSET_KBUMN',
                    init_role: getProfile.value.role._id
                  }
                  if(modalValidasi.value) {
                    store.dispatch(Actions.APPROVAL_ASSETS, payloadValidasi)
                    .then((res) => {
                      disableButton.value = false
                      router.push({ path: '/assets/list' });
                    })
                  } else {
                    disableButton.value = false
                    router.push({ path: '/assets/list' });
                  }
                })
                .catch(() => {
                  disableButton.value = false
                })
              } else {
                press_manage.value = true
                submitButton.value?.setAttribute('data-kt-indicator', 'off')
                disableButton.value = false
                ElMessage({
                  message: 'Tekan tombol hijau pada kolom aksi (✓ ) untuk menambahkan data pengelolaan',
                  type: 'error',
                });
              }
            } else {
              if (forms.array_upaya_pemanfaatan.length > 0 && !show_form_upata.value) {
                store.dispatch(Actions.CREATE_ASSETS, payloadIdle)
                .then((res) => {
                  router.push({ path: '/assets/list' });
                  const payloadValidasi = {
                    user_id : getProfile.value._id,
                    entity_id: res.data._id,
                    action: 'ASSET.request_review',
                    entity: 'ASSET_KBUMN',
                    init_role: getProfile.value.role._id
                  }
                  if(modalValidasi.value) {
                    store.dispatch(Actions.APPROVAL_ASSETS, payloadValidasi)
                    .then((res) => {
                      disableButton.value = false
                      router.push({ path: '/assets/list' });
                    })
                  } else {
                    disableButton.value = false
                    router.push({ path: '/assets/list' });
                  }
                })
                .catch(() => {
                  disableButton.value = false
                })
              } else {
                press_manage_idle.value = true
                submitButton.value?.setAttribute('data-kt-indicator', 'off')
                disableButton.value = false
                ElMessage({
                  message: 'Tekan tombol hijau pada kolom aksi (✓ ) untuk menambahkan data Upaya Pemanfaatan',
                  type: 'error',
                });
              }
            }
          } else {
            if (tempNilai.length === 0 && tempRevaluasi.length === 0) {
              alertDataNilai.value = true;
              alertDataNilaiRevaluasi.value = true;
            }
            disableButton.value = false
            ElMessage({
              message: 'Masih terdapat field yang kosong atau isian tidak sesuai, Silahkan Cek Kembali',
              type: 'error',
            });
          }
          disableButton.value = false
          submitButton.value?.setAttribute('data-kt-indicator', 'off');
        });
      };
  
      const getListCurrency = computed(() => {
        return store.getters.getListCurrency;
      });
  
      const addMapMarker = ref('') as any;
      const addMap = ref('') as any;
      const coordinate = ref({
        lat: '',
        long: '',
      }) as any;
  
      const getPoint = () => {
        if (addMapMarker.value.pinMarker.lat != '') {
          let lat = addMapMarker.value.pinMarker.lat;
          let lng = addMapMarker.value.pinMarker.lng;
          coordinate.value = {
            lat: lat,
            lng: lng,
          };
  
          tempCoordinate.value = lat + ',' + lng;
          forms.coor = tempCoordinate.value;
        }
  
        polygonTemp.value = addMapMarker.value.pinPolygon;
        polylineTemp.value = addMapMarker.value.pinPolyline;
      };
  
      const createPinPoint = (type, index = 0) => {
        let point = [] as any;
        let validatePin = { msg: '', state: true };
        if (type == 'marker') {
          tempCoordinate.value = tempCoordinate.value.replace(/\s+/g, '');
          point = tempCoordinate.value.split(',');
          validatePin = validationPin('marker', point);
        } else if (type == 'polygon') {
          polygonTemp.value[index] = polygonTemp.value[index].replace(/\s+/g, '');
          point = polygonTemp.value[index].split(',');
          validatePin = validationPin('polygon', point);
        } else {
          polylineTemp.value[index] = polylineTemp.value[index].replace(
            /\s+/g,
            ''
          );
          point = polylineTemp.value[index].split(',');
          validatePin = validationPin('polyline', point);
        }
  
        let lat = [] as any;
        let long = [] as any;
        let merged = [] as any;
        switch (validatePin.state) {
          case true:
            switch (type) {
              case 'marker':
                if (point[1]) {
                  if (point.length > 0) {
                    addMapMarker.value.pinMarker = {
                      lat: point[0],
                      lng: point[1],
                    };
  
                    addMap.value.addMarker('marker', [point[0], point[1]]);
                    addMapMarker.value.addMarker('marker', [point[0], point[1]]);
                  }
                }
                break;
              case 'polygon':
                point.forEach((str, index) => {
                  if (index % 2 == 0) {
                    lat.push(str);
                  }
                  if (index % 2) {
                    long.push(str);
                  }
                });
  
                for (let i = 0; i < lat.length; i++) {
                  merged.push([lat[i], long[i]]);
                }
                polygonTemp.value[index] = merged;
                addMapMarker.value.addMarker('polygon', [merged]);
                addMap.value.addMarker('polygon', [merged]);
                addMapMarker.value.pinPolygon = [merged];
                addMap.value.pinPolygon = [merged];
                break;
              case 'polyline':
                point.forEach((str, index) => {
                  if (index % 2 == 0) {
                    lat.push(str);
                  }
                  if (index % 2) {
                    long.push(str);
                  }
                });
  
                for (let i = 0; i < lat.length; i++) {
                  merged.push([lat[i], long[i]]);
                }
                polylineTemp.value[index] = merged;
                addMapMarker.value.addMarker('polyline', [merged]);
                addMap.value.addMarker('polyline', [merged]);
                addMapMarker.value.pinPolyline = [merged];
                addMap.value.pinPolyline = [merged];
                break;
              default:
            }
            break;
          default:
            switch (type) {
              case 'marker':
                tempCoordinate.value = '';
                break;
              case 'polygon':
                polygonTemp.value[index] = '';
                break;
              case 'polyline':
                polylineTemp.value[index] = '';
                break;
              default:
                console.log('not spesific pin type');
            }
            mapLocation.value = false;
            ElMessage({
              message: validatePin.msg,
              type: 'warning',
            });
        }
      };
  
      let mapLocation = ref(false);
      const centerCoordinate = ref({
        lat: "-6.175360400261532",
        lng: "106.82713134232675"
      });
      
      const invalidPolygon = () => {
        ElMessage({
          message: 'Anda belum memasukan Polygon, Harap masukan Polygon terlebih dahulu.',
          type: 'error',
        });
      }

      const cancelEditMap = () => {
        mapLocation.value = false
        polygonTemp.value = []
        polylineTemp.value = []
        addMapMarker.value.pinPolygon = []
        addMapMarker.value.pinPolyline = []
      }

      const openKoordinat = () => {
        polygonTemp.value = polygonTemp.value.filter((letter) => letter !== "")
        const temp = tempCoordinate.value.split(',');
        let lang = [temp[0], temp[1]];
  
        if (mapLocation.value) {
          mapLocation.value = false;
          // addMap.value.getCenterMap();
          addMap.value.center = addMapMarker.value.center;
          addMap.value.refreshMap();
          if (tempCoordinate.value != '') {
            addMap.value.addMarker('marker', lang);
            addMap.value.addMarker('polygon', polygonTemp);
            addMap.value.addMarker('polyline', polylineTemp);
          }
        } else {
          mapLocation.value = true;
  
          if (addMap.value.center[0] != '') {
            addMapMarker.value.center = addMap.value.center;
            addMapMarker.value.refreshMap();
          }
          if (tempCoordinate.value != '') {
            addMapMarker.value.addMarker('marker', lang);
            addMapMarker.value.addMarker('polygon', polygonTemp);
            addMapMarker.value.addMarker('polyline', polylineTemp);
          }
  
        }
      };
  
      const removePoint = (index, type) => {
        if (type == 'polygon') {
          polygonTemp.value.splice(index, 1);
        }
  
        if (type == 'polyline') {
          polylineTemp.value.splice(index, 1);
        }
  
        // addMapMarker.value.removeMarker(index, type);
        /**
         * Refresh Map
         */
        const temp = tempCoordinate.value.split(',');
        let lang = [temp[0], temp[1]];
        addMap.value.refreshMap();
        addMapMarker.value.refreshMap();
        if (tempCoordinate.value != '') {
          addMap.value.addMarker('marker', lang);
          addMap.value.addMarker('polygon', polygonTemp);
          addMap.value.addMarker('polyline', polylineTemp);
  
          addMapMarker.value.addMarker('marker', lang);
          addMapMarker.value.addMarker('polygon', polygonTemp);
          addMapMarker.value.addMarker('polyline', polylineTemp);
        }
      };
  
      watch(
        () => _.cloneDeep(forms),
        (currentValue, oldValue) => {
          let province = '';
          let village = '';
  
          if (currentValue.province_id != '') {
            province = getProvince.value.filter((item) =>
              item._id.toLowerCase().includes(currentValue.province_id)
            );
            province = province[0]['name'];
          }
  
          if (currentValue.village_id != '') {
            village = getVillage.value.filter((item) =>
              item._id.toLowerCase().includes(currentValue.village_id)
            );
            village = village[0]['name'];
          }
  
          if (currentValue.village_id != oldValue.village_id) {
            let address = village.concat(' ' + province);
            tempCoordinate.value = '';
            polygonTemp.value = [];
            polylineTemp.value = [];
            addMap.value.setLatLong(address);
            addMapMarker.value.setLatLong(address);
            addMapMarker.value.pinPoint = address;
          }
        }
      );
  
      watch(tempCoordinate, () => {
        forms.coor = tempCoordinate.value;
      });

      const press_manage = ref(false)
      const press_manage_idle = ref(false)
  
      const addPinMarker = (payload) => {
        switch (payload) {
          case 'polygon':
            polygonTemp.value.push('');
            break;
          default:
            polylineTemp.value.push('');
        }
      };
  
      const validationPin = (type, payload) => {
        let res = { msg: '', state: true };
        if (payload.length % 2 > 0) {
          res = {
            msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
            state: false,
          };
        }
        if (type == 'marker' && payload.length < 2) {
          res = {
            msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
            state: false,
          };
        }
        if (type === 'marker' && payload.length > 2) {
          res = {
            msg: 'Nilai area koordinat lokasi tidak valid, mohon periksa kembali',
            state: false,
          };
        }
        if ((type === 'polyline' || type === 'polygon') && payload.length < 4) {
          res = {
            msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
            state: false,
          };
        }
        return res;
      };
  
      onMounted(() => {
        // sementara hardcode
        store.dispatch(
          Actions.GET_ASSET_CATEGORY_LIST,
          '631577ec8c130d3fa0415fe8'
        );
      });
  
      const mapZoom = ref(7)
  
      const reCenterMapByLocationName = async (range='province') => {
        var search = ''
        if (range == 'village' && forms.village_id.length > 1) {
          search = getVillage.value.find((el) => el._id == forms.village_id).name
          mapZoom.value = 15
        }
        else if (range == 'district' && forms.district_id.length > 1) {
          forms.village_id = ''
          search = getDistrict.value.find((el) => el._id == forms.district_id).name
          mapZoom.value = 12
        }
        else if (range == 'regency' && forms.regency_id.length > 1) {
          forms.village_id = ''
          forms.district_id = ''
          search = getRegency.value.find((el) => el._id == forms.regency_id).name
          mapZoom.value = 10
        }
        else if (range == 'province' && forms.province_id.length > 1) {
          forms.regency_id = ''
          forms.district_id = ''
          forms.village_id = ''
          search = getProvince.value.find((el) => el._id == forms.province_id).name
          mapZoom.value = 9
        } else search = "DKI JAKARTA"
        searchOpenStreet(search)
      };
  
      const searchOpenStreet = async (query) => {
        const provider = new OpenStreetMapProvider({
          params: {
            "accept-language": "id",
            countrycodes: "id", // limit search results to the Indonesia
            addressdetails: 1, // include additional address detail parts
          },
        });
        let results = await provider.search({ query });
        const foundCoordinate = results[0];
        
        if (foundCoordinate) {
          centerCoordinate.value = {
            lat: foundCoordinate?.y.toString(),
            lng: foundCoordinate?.x.toString()
          }
          addMap.value.setCenterMap(centerCoordinate.value, mapZoom.value);
        }
      }

      const asterisk = ref('right')
  
      
  
      return {
        t,
        images,
        asterisk,
        forms,
        formatNumber,
        parseNumber,
        disableYear,
        tempNilaiRevaluasi,
        tempNilaiBuku,
        // tempLuasPemanfaatan,
        addProduktif,
        actProduktif,
        nilaiStatusPerolehan,
        alertDataNilai,
        alertDataNilaiRevaluasi,
        btnRed,
        mapPoint,
        polygon,
        polyline,
        polygonTemp,
        polylineTemp,
        trash,
        btnGreen,
        btnCancel,
        //   v-model
        province,
        regency,
        district,
        village,
        channel,
        street,
        submitButton,
        drawerNilai,
        dialogVisibleDoc,
        pointmapInput,
        polygonInput,
        polylineInput,
        btnEdit,
        btnDelete,
        getProvince,
        getRegency,
        getVillage,
        getDistrict,
        getChannel,
        formatPrice,
        //
        getRegencyById,
        getDistrictById,
        getVillageById,
        submitAssets,
        AjukanValidasi,
        assetValidasi,
        modalValidasi,
        checkClear,
        getJenisAset,
        getLegalStatus,
        getStatusTanah,
        getStatusPerolehan,
        getKeteranganAset,
        delPengelolaan,
        keterangan,
        getListProduktivitas,
        getListPengguna,
        getListPengelolaan,
        disabledForm,
        getListUpayaPemanfaatan,
        NumbersOnly,
        getPoint,
        addMapMarker,
        getArrayPhoto,
        getArrayDoc,
        tempArrPhoto,
        addMap,
        createPinPoint,
        coordinate,
        actEditPengelolaan,
        idPengelolaan,
        tempCoordinate,
        years,
        selectYear,
        getListCurrency,
        currencyRevaluation,
        currencyBook,
        dataBook,
        dataRevaluation,
        flagId,
        closeDrawer,
        openDrawer,
        pushNilaiData,
        delListAssetValue,
        editDataNilai,
        openEditDataNilai,
        openEditDataRevaluasi,
        actionDataValue,
        deleteDataValue,
        namePengguna,
        namePengelolaan,
        namaUpaya,
        getProfile,
        tempData,
        tempNilai,
        tempRevaluasi,
        editManage,
        isolation,
        data_pengelolaan,
        editPengelolaan,
        rules,
        dateMandatory,
        upayaMandatory,
        formRef,
        formRefNilai,
        add,
        mapLocation,
        centerCoordinate,
        openKoordinat,
        cancelEditMap,
        invalidPolygon,
        removePoint,
        addPinMarker,
        reCenterMapByLocationName,
        disableDate,
        disableButton,
        mapZoom,
        disable_add_efforts,
        add_efforts,
        show_form_upata,
        add_field_effort,
        btnClose,
        delUpaya,
        editUpaya,
        actEditUpaya,
        editManageUpaya,
        idUpaya,
        validCoor,
        press_manage,
        press_manage_idle,
        ruleForm,
        rulesDataNilai,
        handleDoubleClick,
        isButtonDisabled
      };
    },
  });
  