
import 'vue-json-viewer/style.css'
import JsonViewer from 'vue-json-viewer'
import { ActionsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { capitalize, dateFormat } from '@/core/helpers/helps';
import moment from "moment";

  export default {
    name: "history-asset",
    components: {
        JsonViewer
    },
    props: {
      search: {
        required: false,
        default: '',
        type: String,
      }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const payload = computed(() => store.getters.ActivityHistory);
        const assetSelected = computed(() => store.getters.getAssetSelected);
        const loading = computed(() => store.getters.LoadingActivityHistory);
        const currentPage = computed(() => store.getters.ActivityHistoryParams.page_num)
        const pageSize = computed(() => store.getters.ActivityHistoryParams.data_limit)
        const dataTotal = computed(() => store.getters.ActivityHistoryParams.total_data)

        const timeLocal = (tm) => { 
            return moment.utc(tm).local().format("DD MMM YYYY")
        }
        const hourLocal = (tm) => {
            return moment.utc(tm).local().format("HH:mm")
        }

        const fetchData = (params:any) => {
            if (Array.isArray(payload.value) && payload.value.length > 0) {
                store.dispatch(ActionsAdminValidasi.GET_ACTITVITY_HISTORY, {
                    channel_id :payload.value[0].channel_id,
                    user_id : store.getters.getUserProfile._id,
                    ...params
                })
            } 
        }

        const handleSizeChange = (val: number) => {
            fetchData({
                ...assetSelected.value,
                limit : val
            })
        }
        const handleCurrentChange = (val: number) => {
            fetchData({
                ...assetSelected.value,
                page : val
            })
        }
        
      return {
        close,
        t,
        payload,
        store,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        currentPage,
        pageSize,
        dataTotal,
        loading,
        capitalize,
        dateFormat,
        timeLocal,
        hourLocal
      };
    },
  };
  