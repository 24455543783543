
import { defineComponent, onMounted, computed, watch} from "vue";
import formAssets from "@/components/forms/AddAsset.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPermissionUpdate } from "@/core/helpers/helps";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "List of Assets",
  components: {
    formAssets,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const getProvince = computed(() => {
      return store.getters.getListProvince;
    });

    const getRegency = computed(() => {
      return store.getters.getListRegency;
    });

    const getDistrict = computed(() => {
      return store.getters.getListDistrict;
    });

    const getVillage = computed(() => {
      return store.getters.getListVillage;
    });

    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });

    const getUser = computed(() => {
      return store.state.AuthModule
    });

    onMounted(() => {
      if (!checkPermissionUpdate('assetlist', 'assetlist.create')) {
        router.push({
          path: '/404',
        });
      }
      store.dispatch(Actions.GET_PROVINCE_LIST);
      store.dispatch(Actions.GET_CHANNEL_LIST);
      store.dispatch(
        Actions.GET_STATUS_LEGAL_ASSETS,
        "6316769d8c130d3fa041611c"
      );
      store.dispatch(
        Actions.GET_STATUS_TANAH_ASSETS,
        "631689248c130d3fa0416184"
      );
      store.dispatch(Actions.GET_STATUS_PEROLEHAN_LIST, "6316f26a8c130d3fa04163c7");
      store.dispatch(Actions.GET_KETERANGAN_ASSETS, "6317d3195f05cc92cf2d5540");
      store.dispatch(Actions.GET_STATUS_PRODUKTIVITAS, "6317d89a5f05cc92cf2d5572");
      store.dispatch(Actions.GET_USER_ASSETS, "6317dd3e5f05cc92cf2d5596");
      store.dispatch(Actions.GET_STATUS_PENGELOLAAN, "6317df375f05cc92cf2d55ce");
      store.dispatch(Actions.GET_UPAYA_PEMANFAATAN, "6317e2b55f05cc92cf2d5607");
      store.dispatch(Actions.GET_CURRENCY)

      setCurrentPageBreadcrumbs("Create New Assets", ["Assets"]);
    });
    return {
      getProvince,
      getRegency,
      getVillage,
      getDistrict,
      getChannel,
      getUser,
    };
  },
});
