
import { computed, defineComponent, reactive, ref, onMounted } from "vue";
import imagesAsset from "@/assets/ts/images.js";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "dataNilai",
  props: {
    data: {
      required: false,
      type: Array,
      default: Array,
    },
    label: {
      type: String,
    },
    year: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const datas = ref([]) as any;
    const labels = props.label as any;
    const btnEdit = imagesAsset.btnEdit;
    const btnDelete = imagesAsset.btnDelete;

    const editDataNilai = (data) => {
      const payload = {
        data: data,
        type: props.label,
      };
      emit("actionDataValue", payload);
    };

    const deleteDataNilai = (data) => {
      const payload = {
        data: data,
        type: props.label,
      };
      emit("deleteDataValue", payload);
    };

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    onMounted(() => {
      datas.value = props.data;
    });

    return {
      t,
      datas,
      labels,
      btnEdit,
      btnDelete,
      formatPrice,
      editDataNilai,
      deleteDataNilai,
    };
  },
});
