
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import imagesAsset from '@/assets/ts/images.js';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import LeafletMap from '@/components/leaflet/Leaflet.vue';
import ImageUpload from '@/components/upload/ImageUpload.vue';
import FileUpload from '@/components/upload/FileUpload.vue';
import { useDropzone } from 'vue3-dropzone';
import type { ElForm } from 'element-plus';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import TableDataNilai from '@/components/table/TableDataNilai.vue'
import { OpenStreetMapProvider } from 'leaflet-geosearch';

interface RuleForm {
  selectYear: string,
  currencyBook: string,
  currencyRevaluation: string,
  tempNilaiBuku: string,
  tempNilaiRevaluasi: string,
  type: string,
  id: number
}

export default defineComponent({
  name: 'List of Assets',
  components: { LeafletMap, ImageUpload, FileUpload, TableDataNilai },
  setup() {
    const { t } = useI18n();

    const keterangan = ref([]);
    const dataBook = ref([]);
    const dataRevaluation = ref([]);
    const store = useStore();
    const images = inject('$images');
    const router = useRouter();
    const route = useRoute()
    const buttonSave = ref(false);

    const mapPoint = imagesAsset.addPoint;
    const polygon = imagesAsset.polygon;
    const polyline = imagesAsset.polyline;
    const pointmapInput = imagesAsset.pointmapInput;
    const polygonInput = imagesAsset.polygonInput;
    const polylineInput = imagesAsset.polylineInput;
    const trash = imagesAsset.trash;
    const btnGreen = imagesAsset.btnGreen;
    const btnClose = imagesAsset.close;
    const btnCancel = imagesAsset.cancel;
    const btnRed = imagesAsset.btnRed;
    const btnEdit = imagesAsset.btnEdit;
    const btnDelete = imagesAsset.btnDelete;
    const addPhoto = imagesAsset.addPhoto;
    const iconPdf = imagesAsset.pdf;
    const iconXlsx = imagesAsset.xlsx;
    const iconDocx = imagesAsset.docx;
    const polylineTemp = ref([]) as any;
    const polygonTemp = ref([]) as any;
    const disableButton = ref(false);

    const currencyRevaluation = ref('');
    const currencyBook = ref('');

    const addProduktif = ref(true);

    const tempPhoto = ref({
      filesPhoto: [] as any,
    });
    const tempFile = ref({
      filesDoc: [] as any,
    });
    let photo = [] as any;
    let doc = [] as any;

    const typeDoc = ref('');

    const asetFile = (type) => {
      if (type === 'img') {
        typeDoc.value = 'image/*';
      } else if (type === 'doc') {
        typeDoc.value = '.xlsx,.xls,.doc, .docx,.pdf';
      }
    };

    function onDrop(acceptFiles, rejectReasons) {
      if (/\.(jpg|jpeg|png|webp|avif|gif|JPG|JPEG|PNG)$/.test(acceptFiles[0].name)) {
        photo = acceptFiles;
        PhotoName.value = photo[0].name;
      } else {
        doc = acceptFiles;
        documentName.value = doc[0].name;
      }
    }

    const documentName = ref('');
    const PhotoName = ref('');

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept: typeDoc.value,
    });

    const checkClear = (id) => {
      if (id === '631676fe8c130d3fa041611f') {
        forms.keterangan_aset = [];
      }
    };

    const counterImage = ref(5);
    const counterDoc = ref(5);

    const selectedFileDoc = () => {
      buttonSave.value = true;
      const formData: any = new FormData();
      formData.append('files', doc[0]);
      store
        .dispatch(Actions.FILE_UPLOAD_ASSET, formData)
        .then((res) => {
          counterDoc.value--;
          form.attachmentDokumen.push(res.data[0]);
          const objDoc = {
            url: res.data[0].url,
            filename: res.data[0].filename,
            extension: res.data[0].extension,
            filesize: res.data[0].filesize,
            size_unit: res.data[0].size_unit,
            asset_document_type_id: '632020db87192c201e84c9ec',
          };
          forms.attachment.push(objDoc);
          dialogVisibleDoc.value = false;
          documentName.value = '';
          buttonSave.value = false;
        })
        .catch((err) => {
          dialogVisibleDoc.value = false;
          buttonSave.value = false;
          console.log(err);
        });
    };

    const isImgUrl = (url) => {
      if (/\.(pdf)$/.test(url)) {
        return 'pdf';
      } else if (/\.(docx)$/.test(url)) {
        return 'docx';
      } else {
        return 'xlsx';
      }
    };

    const selectedFile = () => {
      const formData: any = new FormData();
      formData.append('files', photo[0]);
      store
        .dispatch(Actions.FILE_UPLOAD_ASSET, formData)
        .then((res) => {
          form.attachmentFoto.push(res.data[0]);
          const objPhoto = {
            url: res.data[0].url,
            filename: res.data[0].filename,
            extension: res.data[0].extension,
            filesize: res.data[0].filesize,
            size_unit: res.data[0].size_unit,
            asset_document_type_id: '631f42a2105c9a39312bd3a9',
          };
          forms.attachment.push(objPhoto);
          PhotoName.value = '';
          counterImage.value--;
          dialogVisible.value = false;
        })
        .catch((err) => {
          console.log(err);
          dialogVisible.value = false;
        });
    };

    const add = () => {
      addProduktif.value = true;
    };

    const actProduktif = () => {
      if (forms.nomor_surat !== '' && forms.nama_pengguna !== '') {
        addProduktif.value = false;
        forms.data_pengelolaan.push({
          id: idPengelolaan.value++,
          nomor_surat : forms.nomor_surat,
          pengguna : {
           name: namePengguna(forms.pengguna._id).name,
           _id: forms.pengguna._id
          },
          nama_pengguna : forms.nama_pengguna,
          status_pengelolaan : {
            name: namePengelolaan(forms.status_pengelolaan._id).name,
            _id: forms.status_pengelolaan._id
          },
          luas_penggunaan : {
            "dimension_category_id": "6317e0aa5f05cc92cf2d55ff",
            "dimension_uom": {
                "name": "m2",
                "long_name": "Meter Persegi",
                "description": "Pilihan luas Penggunaan",
                "code": "PLP",
                "is_active": true,
                "dimension_category_id": "6317e0aa5f05cc92cf2d55ff",
                "_id": "6317e0be5f05cc92cf2d5601",
                "updated_at": "2023-02-14T01:41:14.389Z",
                "created_at": "2023-02-14T01:41:14.389Z"
            },
            "value": Number(forms.luas_penggunaan.value)
          }
        });
        forms.nomor_surat = ''
        forms.pengguna._id = ''
        forms.nama_pengguna = ''
        forms.status_pengelolaan._id = ''
        // forms.luas_penggunaan = ''
      } else {
        ElMessage({
          message: t('detailAssets.validationProductif'),
          type: 'warning',
        });
      }
    };

    const nomor_surat = ref('');
    const pengguna = ref('');
    const penggunax = ref('');
    const nama_pengguna = ref('');
    const status_pengelolaan = ref('');
    const status_pengelolaanx = ref('');
    const luas_penggunaan = ref(0);
    var luas_penggunaan_uom_id = ref('');

    const data_pengelolaan = ref({
      nomor_surat: 0,
      pengguna: '',
      penggunax: '',
      nama_pengguna: '',
      status_pengelolaan: '',
      status_pengelolaanx: '',
      luas_penggunaan: 0,
      luas_penggunaan_uom_id: '6317e0be5f05cc92cf2d5601',
    });

    const editPengelolaan = ref(false);

    const editManage = (obj, id) => {
      idPengelolaan.value = id;
      editPengelolaan.value = true;
      forms.nomor_surat = obj.nomor_surat;
      forms.pengguna = obj.pengguna === null ? {
        "_id": "6317dd6e5f05cc92cf2d559a",
        "name": "Instansi"
      } : obj.pengguna;
      forms.nama_pengguna = obj.nama_pengguna;
      forms.status_pengelolaan = obj.status_pengelolaan === null ? {
        "_id": "6317df725f05cc92cf2d55d2",
        "name": "Dikelola Pihak Lain"
      } : obj.status_pengelolaan;
      forms.luas_penggunaan.value = obj.luas_penggunaan.value;
    };

    const idPengelolaan = ref(0);
    const actEditPengelolaan = () => {
      forms.data_pengelolaan.find((o, i) => {
        if (i === idPengelolaan.value) {
          forms.data_pengelolaan[i] = {
            nomor_surat : forms.nomor_surat,
            pengguna : {
              _id: forms.pengguna._id,
              name: namePengguna(forms.pengguna._id).name,
            },
            nama_pengguna : forms.nama_pengguna,
            status_pengelolaan : {
              _id: forms.status_pengelolaan._id,
              name: namePengelolaan(forms.status_pengelolaan._id).name
            },
            luas_penggunaan : {
              "dimension_category_id": "6317e0aa5f05cc92cf2d55ff",
              "dimension_uom": {
                  "name": "m2",
                  "long_name": "Meter Persegi",
                  "description": "Pilihan luas Penggunaan",
                  "code": "PLP",
                  "is_active": true,
                  "dimension_category_id": "6317e0aa5f05cc92cf2d55ff",
                  "_id": "6317e0be5f05cc92cf2d5601",
                  "updated_at": "2023-02-14T01:41:14.389Z",
                  "created_at": "2023-02-14T01:41:14.389Z"
              },
              "value": Number(forms.luas_penggunaan.value)
            }
          };
        }
      });
      forms.nomor_surat = ''
      forms.pengguna._id = ''
      forms.nama_pengguna = ''
      forms.status_pengelolaan._id = ''

      editPengelolaan.value = false;
    };

    const delPengelolaan = (index) => {
      forms.data_pengelolaan.splice(forms.data_pengelolaan.indexOf(index), 1);
    };

    const forms = reactive({
      id: '' as any,
      name: '',
      external_code: '',
      acquisition_date: '',
      acquisition_value_rp: 0,
      acquisition_value_usd: 0,
      acquisition_status: {
        name: '',
        _id: ''
      },
      type: '',
      channel_id: {
        name: '',
        _id: ''
      },
      province_id: '',
      regency_id: '',
      district_id: '',
      village_id: '',
      address: '',
      coordinate: {
        lat: '',
        long: '',
      },
      atas_nama: '',
      start_date_certificate: '',
      end_date_certificate: '',
      document_code: '',
      document_code_support: '',
      issued_by: '',
      luas_total: '',
      luas_total_uom_id: '63168fa18c130d3fa04161da', // done
      surat_ukur: '',
      status_tanah: '',
      status_legalitas: '631676fe8c130d3fa041611f',
      keterangan_aset: [],
      peruntukan: '',
      luas_status: '',
      polyline: [
        [
          {
            lat: '',
            long: '',
          },
        ],
      ],
      polygon: [
        [
          {
            lat: '',
            long: '',
          },
        ],
      ],
      luas_status_uom_id: '6317d83e5f05cc92cf2d556f', // DONE
      status_produktivitas: '6317d8bf5f05cc92cf2d5574',
      data_pengelolaan: [] as any,
      historical_date: '',
      nomor_surat: '',
      pengguna: {
        name: '',
        _id: ''
      },
      penggunax: '',
      nama_pengguna: '',
      status_pengelolaan: {
        name: '',
        _id: ''
      },
      status_pengelolaanx: '',
      "luas_penggunaan": {
          "dimension_category_id": '6317e0aa5f05cc92cf2d55ff',
          "dimension_uom": {
            "name": "m2",
            "long_name": "Meter Persegi",
            "description": "Pilihan luas Penggunaan",
            "code": "PLP",
            "is_active": true,
            "dimension_category_id": "6317e0aa5f05cc92cf2d55ff",
            "_id": "6317e0be5f05cc92cf2d5601",
            "updated_at": "2023-02-02T06:22:42.710Z",
            "created_at": "2023-02-02T06:22:42.710Z"
          },
          "value": ""
      },
      luas_penggunaan_uom_id: '',
      upaya_pemanfaatan: '',
      array_upaya_pemanfaatan: [] as any,
      luas_pemanfaatan: '',
      luas_pemanfaatan_uom_id: '6317e5795f05cc92cf2d5628', //DONE
      keterangan_pemanfaatan: '',
      nilai_buku: [
        {
          year: '',
          currency_id: '',
          value: 0,
        },
      ] as any,
      nilai_revaluasi: [
        {
          year: '',
          currency_id: '',
          value: 0,
        },
      ] as any,
      selectMap: '' as any,
      attachment: [] as any,
    });
    const dateMandatory = computed(() => {
      return forms.status_tanah === '6316898e8c130d3fa0416189' ||
        forms.status_tanah === '6316899a8c130d3fa041618b'
        ? true
        : false;
    });

    const validCoor = computed(() => {
      return /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(tempCoordinate.value)
    });

    const formRef = ref<InstanceType<typeof ElForm>>();
    const formRefNilai = ref<InstanceType<typeof ElForm>>();
    const rulesDataNilai = reactive({
      selectYear: [
        {
          required: true,
          message: t('dataValueAssets.notice.year'),
          trigger: 'change',
        },
      ],
      currencyBook: [
        {
          required: true,
          message: t('dataValueAssets.notice.currency'),
          trigger: 'change',
        },
      ],
      currencyRevaluation: [
        {
          required: true,
          message: t('dataValueAssets.notice.currency'),
          trigger: 'change',
        },
      ],
      tempNilaiBuku: [
        {
          required: true,
          message: t('dataValueAssets.notice.book_value'),
          trigger: 'blur',
        },
        { pattern: /^\d+$/, message: t('dataValueAssets.min_book'), trigger: 'blur' },
      ],
      tempNilaiRevaluasi: [
        {
          required: true,
          message:  t('dataValueAssets.notice.revaluation_value'),
          trigger: 'blur',
        },
        { pattern: /^\d+$/, message: t('dataValueAssets.min_rev'), trigger: 'blur' },
      ],
    })
    const rules = reactive({
      channel_id: [
        { required: true, message: 'Please select BUMN', trigger: 'change' },
      ],
      type: [
        {
          required: true,
          message: 'Please select type of assets',
          trigger: 'change',
        },
      ],
      name: [
        {
          required: true,
          message: 'Please input name of asset',
          trigger: 'blur',
        },
        {
          pattern: /^[^<>?!]*$/,
          message: 'character <>?!; in not allowed',
        }
      ],
      external_code: [
        {
          required: true,
          message: 'Please input nomor surat of asset',
          trigger: 'blur',
        },
      ],
      acquisition_date: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a acquisition date',
          trigger: 'change',
        },
      ],
      acquisition_status: [
        {
          required: true,
          message: 'Please select acquisition status',
          trigger: 'change',
        },
      ],
      acquisition_value_rp: [
        {
          required: true,
          message: 'Please input acquisition value',
          trigger: 'blur',
        },
      ],
      upaya_pemanfaatan: [
        {
          required: true,
          message: 'Please select upaya pemanfaatan',
          trigger: 'change',
        },
      ],
      province_id: [
        {
          required: true,
          message: 'Please select province of assets',
          trigger: 'change',
        },
      ],
      regency_id: [
        {
          required: true,
          message: 'Please select regency of assets',
          trigger: 'change',
        },
      ],
      village_id: [
        {
          required: true,
          message: 'Please select village of assets',
          trigger: 'change',
        },
      ],
      district_id: [
        {
          required: true,
          message: 'Please select district of assets',
          trigger: 'change',
        },
      ],
      coor: [
        {
          required: true,
          message: 'Please input coordinate value',
          trigger: 'blur',
        },
      ],
      status_tanah: [
        {
          required: true,
          message: 'Please select status tanah',
          trigger: 'change',
        },
      ],
      atas_nama: [
        {
          required: true,
          message: 'Please select atas nama',
          trigger: 'change',
        },
      ],
      start_date_certificate: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a start date certificate',
          trigger: 'change',
        },
      ],
      end_date_certificate: [
        {
          type: 'date',
          required: dateMandatory,
          message: 'Please pick a end date certificate',
          trigger: 'change',
        },
      ],
      luas_total: [
        { required: true, message: 'Please input luas total', trigger: 'blur' },
      ],
      surat_ukur: [
        { required: true, message: 'Please input surat ukur', trigger: 'blur' },
      ],
      status_legalitas: [
        {
          required: true,
          message: 'Please select status legalitas',
          trigger: 'change',
        },
      ],
      peruntukan: [
        { required: true, message: 'Please input peruntukan', trigger: 'blur' },
      ],
      status_produktivitas: [
        {
          required: true,
          message: 'Please select status produktivitas',
          trigger: 'change',
        },
      ],
      document_code: [
        {
          required: true,
          message: 'Please input document code',
          trigger: 'blur',
        },
      ],
      issued_by: [
        { required: true, message: 'Please input issued by', trigger: 'blur' },
      ],
      nomor_surat: [
        {
          required: true,
          message: 'Please input nomor surat',
          trigger: 'blur',
        },
      ],
      pengguna: [
        { 
          required: true,
          message: 'Please input pengguna', 
          trigger: 'change' 
        },
      ],
      nama_pengguna: [
        {
          required: true,
          message: 'Please input nama pengguna',
          trigger: 'blur',
        },
      ],
      luas_penggunaan: [
        {
          required: true,
          message: 'Please input luas pengguna',
          trigger: 'blur',
        },
      ],
      luas_pemanfaatan: [
        {
          required: true,
          message: 'Please input luas pemanfaatan',
          trigger: 'blur',
        },
      ],
      status_pengelolaan: [
        {
          required: true,
          message: 'Please input status pengelolaan',
          trigger: 'change',
        },
      ],
      coordinate: {
        type: 'object',
        required: true,
        fields : {
          lat: {
            required: true,
            message: 'Please input Coordinate',
            trigger: 'change',
          },
        }
      },
    });

    const submitButton = ref<HTMLElement | null>(null);

    const drawerNilai = ref(false);

    const getRegencyById = (id) => {
      store.dispatch(Actions.GET_REGENCY_BY_PROV_LIST, id);
    };

    const getDistrictById = (id) => {
      store.dispatch(Actions.GET_DISTRICT_BY_REGENCY_LIST, id);
    };

    const getVillageById = (id) => {
      store.dispatch(Actions.GET_VILLAGE_BY_DISTRICT_LIST, id);
    };

    const closeDrawer = () => {
      drawerNilai.value = false;
    };

    const removeImage = (img) => {
      form.attachmentFoto.splice(form.attachmentFoto.indexOf(img), 1);
      forms.attachment.splice(forms.attachment.indexOf(img), 1);
    };

    const dialogVisible = ref(false);
    const dialogVisibleDoc = ref(false);
    const tempCoordinate = ref('');

    const dataDocument = [
      {
        title: 'Dokument 1',
        type: 'PDF',
        date: '19 Juni 2022',
        size: '9Mb',
      },
    ];

    const province = ref('');
    const regency = ref('');
    const district = ref('');
    const village = ref('');
    const channel = ref('');
    const street = ref('');
    const tempData = ref([]);

    const show_form_upata = ref(true)

    const disable_add_efforts = computed(() => {
      if (forms.upaya_pemanfaatan !== '' && forms.luas_pemanfaatan !== '') {
        return false
      } else {
        return true
      }
    });

    let id_upaya = 0;

    const add_efforts = () => {
      show_form_upata.value = false
      forms.array_upaya_pemanfaatan.push({
        "id": id_upaya++,
        "upaya_pemanfaatan": forms.upaya_pemanfaatan,
        "luas_pemanfaatan": forms.luas_pemanfaatan,
        "luas_pemanfaatan_uom_id": "6317e5795f05cc92cf2d5628",
        "keterangan_pemanfaatan": forms.keterangan_pemanfaatan
      });
      forms.upaya_pemanfaatan = ''
      forms.luas_pemanfaatan = ''
      forms.keterangan_pemanfaatan = ''
    }

    const add_field_effort = () => {
      show_form_upata.value = true
      forms.upaya_pemanfaatan = ''
      forms.luas_pemanfaatan = ''
      forms.keterangan_pemanfaatan = ''
    }

    const delUpaya = (index) => {
      forms.array_upaya_pemanfaatan.splice(forms.array_upaya_pemanfaatan.indexOf(index), 1);
    };

    const editUpaya = ref(false);

    const editManageUpaya = (obj, id) => {
      idUpaya.value = id;
      editUpaya.value = true;
      forms.upaya_pemanfaatan = obj.upaya_pemanfaatan
      forms.luas_pemanfaatan = obj.luas_pemanfaatan
      forms.keterangan_pemanfaatan = obj.keterangan_pemanfaatan
    };

    const idUpaya = ref(0);
    const actEditUpaya = () => {
      const findObj = forms.array_upaya_pemanfaatan.find((o, i) => {
        if (i === idUpaya.value) {
          forms.array_upaya_pemanfaatan[i] = {
            "upaya_pemanfaatan": forms.upaya_pemanfaatan,
            "luas_pemanfaatan": forms.luas_pemanfaatan,
            "luas_pemanfaatan_uom_id": "6317e5795f05cc92cf2d5628",
            "keterangan_pemanfaatan": forms.keterangan_pemanfaatan
          };
        }
      });
      editUpaya.value = false;
    };

    const getProvince = computed(() => {
      return store.getters.getListProvince;
    });

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const selectYear = ref('');

    const ruleForm = reactive<RuleForm>({
      selectYear: '',
      currencyBook: '',
      currencyRevaluation: '',
      tempNilaiBuku: '',
      tempNilaiRevaluasi: '',
      type: '',
      id: 0
    })

    const years = computed(() => {
      const currentYear = new Date().getFullYear();
      return Array.from(
        { length: currentYear - 1900 + 1 },
        (value, index) => 1900 + index
      );
    });

    const getRegency = computed(() => {
      return store.getters.getListRegency;
    });

    const getDistrict = computed(() => {
      return store.getters.getListDistrict;
    });

    const getVillage = computed(() => {
      return store.getters.getListVillage;
    });

    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });

    const disableDate = (time: Date) => {
      return moment(time).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD");
    };

    const getJenisAset = computed(() => {
      return store.getters.getListCategory;
    });

    const getLegalStatus = computed(() => {
      return store.getters.getListLegal;
    });

    const getStatusTanah = computed(() => {
      return store.getters.getListStatusTanah;
    });

    const getStatusPerolehan = computed(() => {
      return store.getters.getStatusPerolehan;
    });

    const getKeteranganAset = computed(() => {
      return store.getters.getKeteranganAset;
    });

    const getListProduktivitas = computed(() => {
      return store.getters.getListProduktivitas;
    });

    const getListPengguna = computed(() => {
      return store.getters.getListPengguna;
    });

    const getListPengelolaan = computed(() => {
      return store.getters.getListPengelolaan;
    });

    const getListUpayaPemanfaatan = computed(() => {
      return store.getters.getListUpayaPemanfaatan;
    });

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });

    const delDocValue = (index) => {
      form.attachmentDokumen.splice(form.attachmentFoto.indexOf(index), 1);
      forms.attachment.splice(forms.attachment.indexOf(index), 1);
    };

    const delListAssetValue = (index, type) => {
      if (type === "Nilai Buku" || type === 'Book Value') {
        tempNilai.value.splice(tempNilai.value.indexOf(index), 1);
      } else {
        tempRevaluasi.value.splice(tempRevaluasi.value.indexOf(index), 1);
      }
    };


    const disabledForm = ref(0);
    const flagId = ref('');
    const disableYear = ref(false);

    const actionDataValue = (params) => {
      if (params.type === "Nilai Buku" || params.type === "Book Value") {
        openEditDataNilai(params.data);
      } else {
        openEditDataRevaluasi(params.data);
      }
    };

    const deleteDataValue = (params) => {
      delListAssetValue(params.data, params.type);
    };

    const openEditDataNilai = (obj) => {
      disableYear.value = true;
      flagId.value = obj.id;
      disabledForm.value = 1; // 0 show all data, 1. show data nilai buku and else revaluasi
      ruleForm.selectYear = obj.year;
      ruleForm.currencyBook = obj.currency_id;
      ruleForm.tempNilaiBuku = obj.value;
      drawerNilai.value = true;
    };

    const openEditDataRevaluasi = (obj) => {
      disableYear.value = true;
      flagId.value = obj.id;
      disabledForm.value = 2; // 0 show all data, 2. show data nilai buku and else revaluasi
      ruleForm.selectYear = obj.year;
      ruleForm.currencyRevaluation = obj.currency_id;
      ruleForm.tempNilaiRevaluasi = obj.value;
      drawerNilai.value = true;
    };

    var alertDataNilai = ref(false);
    var alertDataNilaiRevaluasi = ref(false);

    const editDataNilai = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.validate((valid, fields) => {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        if (valid) {
          if (disabledForm.value === 1) {
            drawerNilai.value = false;
            tempNilai.value.find((o, i) => {
              if (o.id === flagId.value) {
                tempNilai.value[i] = {
                  currency_id: ruleForm.currencyBook,
                  value: ruleForm.tempNilaiBuku,
                  year: ruleForm.selectYear,
                  type: "Nilai Buku",
                  id: o.id
                };
              }
            });
          } else if (disabledForm.value === 2) {
            drawerNilai.value = false;
            tempRevaluasi.value.find((o, i) => {
              if (o.id === flagId.value) {
                tempRevaluasi.value[i] = {
                  currency_id: ruleForm.currencyRevaluation,
                  value: ruleForm.tempNilaiRevaluasi,
                  year: ruleForm.selectYear,
                  type: 'Nilai Revaluasi',
                  id: o.id
                };
              }
            });
          }
        } else {
          ElMessage({
            message: t('dataValueAssets.alert'),
            type: 'error',
          });
        }
        submitButton.value?.setAttribute('data-kt-indicator', 'off');
      })
    };

    let numList = 1;
    let numListRevaluation = 1;

    const form = reactive({
      name: '',
      type: '',
      channel_id: '',
      external_code: '',
      acquisition_date: '',
      acquisition_value_rp: '',
      acquisition_value_usd: '',
      acquisition_status: '',
      approval_status: '',
      province: '',
      district: '',
      regency: '',
      village: '',
      attachmentFoto: [] as any,
      attachmentDokumen: [
        {
          asset_info_id: '',
          filename: '',
          extension: '',
          size: '',
          updated_at: '',
          url: '',
        },
      ],
      geolocation: {
        coordinate: {
          lat: '',
          long: '',
        },
        polyline: [
          {
            lat: '',
            long: '',
          },
        ],
        polygon: [
          {
            lat: '',
            long: '',
          },
        ],
      },
      asset_administration: [
        {
          asset_info_id: '',
          keterangan_aset: '',
          peruntukan: '',
          status_legalitas: '',
          status_produktivitas: '',
        },
      ],
      asset_ownership: {
        asset_dimension: {
          _id: '',
          value: '',
          child_asset_dimension: {
            name: '',
          },
        },
        atas_nama: '',
        document_code: '',
        document_code_support: '',
        start_date_certificate: '',
        end_date_certificate: '',
        issued_by: '',
        luas_total: '',
        status: '',
        surat_ukur: '',
      },
      data_pengelolaan: [
        {
          asset_info_id: '',
          luas_penggunaan: '',
          nama_pengguna: '',
          nomor_surat: '',
          pengguna: '',
          status_pengelolaan: '',
        },
      ],
      asset_administration_keterangan_aset_category_value: [],
      asset_administration_status_produktivitas_category_value: {},
      asset_administration_luas_status: {},
      asset_administration_status_legalitas_category_value: {},
      data_pengelolaan_pengguna_child_status: {},
      historical_value: [
        {
          historical_date: '',
          currency_id: '',
          value: '',
        },
        {
          historical_date: '',
          currency_id: '',
          value: '',
        },
      ],
      nilai_buku: [
        {
          id: '',
          historical_year: '',
          currency: '',
          value: '',
        },
      ],
      nilai_revaluasi: [
        {
          id: '',
          historical_year: '',
          currency: '',
          value: '',
        },
      ],
    });

    const openDrawer = () => {
      disableYear.value = false;
      disabledForm.value = 0;
      drawerNilai.value = true;
      ruleForm.tempNilaiBuku = '';
      ruleForm.tempNilaiRevaluasi = '';
      ruleForm.selectYear = '';
      ruleForm.currencyBook = '6315cf948c130d3fa04160fe';
      ruleForm.currencyRevaluation = '6315cf948c130d3fa04160fe';
    };

    var tempNilai = ref([]) as any;
    var tempRevaluasi = ref([]) as any;

    const pushNilaiData = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;

      formEl.validate((valid, fields) => {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        if (valid) {
          tempNilai.value.push({
            id: numList++,
            year: ruleForm.selectYear,
            currency_id: ruleForm.currencyBook,
            value: ruleForm.tempNilaiBuku,
            type: 'Nilai Buku'
          } as never);

          drawerNilai.value = false;
          alertDataNilai.value = false;

          tempRevaluasi.value.push({
            id: numListRevaluation++,
            year: ruleForm.selectYear,
            currency_id: ruleForm.currencyRevaluation,
            value: ruleForm.tempNilaiRevaluasi,
            type: 'Nilai Revaluasi'
          } as never);

          drawerNilai.value = false;
          alertDataNilaiRevaluasi.value = false;
        } else {
          ElMessage({
            message: t('dataValueAssets.alert'),
            type: 'error',
          });
        }
        submitButton.value?.setAttribute('data-kt-indicator', 'off');
      })
    };

    const NumbersOnly = (e) => {
      const char = String.fromCharCode(e.keyCode);
      if (/^\d+$/.test(char)) return true;
      else e.preventDefault();
    };

    const formatNumber = (num) => {
      return Number(num).toLocaleString();
    };

    const formatFileExt = (value) => {
      let extension = value.split('.').pop();
      return extension.toUpperCase();
    };

    const formatDate = (value) => {
      return moment(value).format('DD MMMM YYYY');
    };

    const formatSize = (value) => {
      return Math.ceil(value);
    };

    const parseNumber = (text) => {
      return Number(text.replace('$', '').replace(/,/g, ''));
    };

    const tempNilaiRevaluasi = computed({
      get() {
        return formatNumber(forms.nilai_revaluasi.value);
      },
      set(newValue) {
        forms.nilai_revaluasi.value = parseNumber(newValue);
      },
    });

    const tempNilaiBuku = computed({
      get() {
        return formatNumber(forms.nilai_buku.value);
      },
      set(newValue) {
        forms.nilai_buku.value = parseNumber(newValue);
      },
    });

    const nilaiStatusPerolehan = computed({
      get() {
        return formatNumber(forms.acquisition_value_rp);
      },
      set(newValue) {
        forms.acquisition_value_rp = parseNumber(newValue);
      },
    });    

    const modalValidasi = ref(false)

    const delay = 500; 
    const isButtonDisabled = ref(false);

    const handleDoubleClick = (customData: string, event: MouseEvent) => {

      if (!isButtonDisabled.value) {
        if (customData === 'propose') {
          AjukanValidasi(formRef.value, event);
        } else {
          submitAssets(formRef.value, event)
        }
        isButtonDisabled.value = true;
        setTimeout(() => {
          isButtonDisabled.value = false;
        }, delay);
      }
    };

    const AjukanValidasi = (formEl: InstanceType<typeof ElForm> | undefined, event: MouseEvent) => {
      event.preventDefault();
      if (!formEl) return;

      formEl.validate((valid, fields) => {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        if (valid && tempNilai.value.length > 0 && tempRevaluasi.value.length > 0) {
          modalValidasi.value = true;
        } else {
          alertDataNilai.value = true;
          alertDataNilaiRevaluasi.value = true;
          ElMessage({
            message: 'Masih terdapat field yang kosong atau isian tidak sesuai, Silahkan Cek Kembali',
            type: 'error',
          });
        }
        submitButton.value?.setAttribute('data-kt-indicator', 'off');
      })
    };

    const submitAssets = (formEl: InstanceType<typeof ElForm> | undefined, event: MouseEvent) => {
      event.preventDefault();
      if (!formEl) return;

      press_manage.value = false
      press_manage_idle.value = false
      const temp = tempCoordinate.value.split(',');
      forms.coordinate.lat = temp[0];
      forms.coordinate.long = temp[1];

      var polygonPin = [] as any;
      if (polygonTemp.value.length > 0) {
        polygonTemp.value.forEach((x: any) => {
          var tempolygonPin = [] as any;
          if (Array.isArray(x)) {
            x.forEach((y) => {
              tempolygonPin.push({ lat: y[0], long: y[1] });
            });
            polygonPin.push(tempolygonPin);
          }
        });
      }

      var polylinePin = [] as any;
      if (polylineTemp.value.length > 0) {
        polylineTemp.value.forEach((x: any) => {
          var tempolylinePin = [] as any;
          if (Array.isArray(x)) {
            x.forEach((y) => {
              tempolylinePin.push({ lat: y[0], long: y[1] });
            });
            polylinePin.push(tempolylinePin);
          }
        });
      }

      if (!formEl) return;

      formEl.validate((valid, fields) => {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        if (valid && tempNilai.value.length > 0 && tempRevaluasi.value.length > 0 && validCoor.value) {
          disableButton.value = true
          type Payload = {
            [key: string]: any;
          };

          forms.attachment = [];
          if (tempArrPhoto.length) {
            tempArrPhoto.map((photo) => {
              forms.attachment.push(photo);
            });
          }

          if (tempArrDoc.length) {
            tempArrDoc.map((doc) => {
              forms.attachment.push(doc);
            });
          }

          const payload: Payload = {
            id: forms.id,
            name: forms.name,
            external_code: forms.external_code,
            acquisition_date: forms.acquisition_date,
            acquisition_value_rp: Number(forms.acquisition_value_rp),
            acquisition_value_usd: 2,
            acquisition_status: forms.acquisition_status._id,
            type: forms.type,
            channel_id: forms.channel_id._id,
            province_id: forms.province_id,
            regency_id: forms.regency_id,
            district_id: forms.district_id,
            village_id: forms.village_id,
            address: forms.address,
            coordinate: forms.coordinate,
            polyline: polylinePin,
            polygon: polygonPin,
            atas_nama: forms.atas_nama,
            document_code: forms.document_code,
            document_code_support: forms.document_code_support,
            issued_by: forms.issued_by,
            luas_total: Number(forms.luas_total),
            luas_total_uom_id: '63168fa18c130d3fa04161da',
            surat_ukur: forms.surat_ukur,
            status_tanah: forms.status_tanah,
            status_legalitas: forms.status_legalitas,
            keterangan_aset: forms.keterangan_aset,
            peruntukan: forms.peruntukan,
            luas_status: Number(forms.luas_status),
            luas_status_uom_id: '6317d83e5f05cc92cf2d556f',
            status_produktivitas: forms.status_produktivitas,
            data_pengelolaan: forms.data_pengelolaan,
            historical_date: forms.acquisition_date,
            nilai_buku: forms.nilai_buku,
            nilai_revaluasi: forms.nilai_revaluasi,
            attachment: forms.attachment,
          };

          type PayloadIdle = {
            [key: string]: any;
          };

          const payloadIdle: PayloadIdle = {
            id: forms.id,
            name: forms.name,
            external_code: forms.external_code,
            acquisition_date: forms.acquisition_date,
            acquisition_value_rp: Number(forms.acquisition_value_rp),
            acquisition_value_usd: 2,
            acquisition_status: forms.acquisition_status._id,
            type: forms.type,
            channel_id: forms.channel_id._id,
            province_id: forms.province_id,
            regency_id: forms.regency_id,
            district_id: forms.district_id,
            village_id: forms.village_id,
            address: forms.address,
            coordinate: forms.coordinate,
            polyline: polylinePin,
            polygon: polygonPin,
            atas_nama: forms.atas_nama,
            document_code: forms.document_code,
            document_code_support: forms.document_code_support,
            issued_by: forms.issued_by,
            luas_total: Number(forms.luas_total),
            luas_total_uom_id: '63168fa18c130d3fa04161da',
            surat_ukur: forms.surat_ukur,
            status_tanah: forms.status_tanah,
            status_legalitas: forms.status_legalitas,
            keterangan_aset: forms.keterangan_aset,
            peruntukan: forms.peruntukan,
            luas_status: Number(forms.luas_status),
            luas_status_uom_id: '63168fa18c130d3fa04161da',
            status_produktivitas: forms.status_produktivitas,
            upaya_pemanfaatan: forms.array_upaya_pemanfaatan,
            luas_pemanfaatan: Number(forms.luas_pemanfaatan),
            luas_pemanfaatan_uom_id: '63168fa18c130d3fa04161da',
            keterangan_pemanfaatan: forms.keterangan_pemanfaatan,
            historical_date: forms.acquisition_date,
            nilai_buku: forms.nilai_buku,
            nilai_revaluasi: forms.nilai_revaluasi,
            attachment: forms.attachment,
          };

          if (forms.end_date_certificate !== '') {
            payload.end_date_certificate = forms.end_date_certificate;
            payloadIdle.end_date_certificate = forms.end_date_certificate;
          }

          if (forms.start_date_certificate !== '') {
            payload.start_date_certificate = forms.start_date_certificate;
            payloadIdle.start_date_certificate = forms.start_date_certificate;
          }

          if (forms.status_produktivitas === '6317d8bf5f05cc92cf2d5574') {
            if (forms.data_pengelolaan.length > 0 && !addProduktif.value) {
              store.dispatch(Actions.UPDATE_ASSETS, payload)
              .then((res) => {
                disableButton.value = false
                const payloadValidasi = {
                  user_id : getProfile.value._id,
                  entity_id: res.data._id,
                  action: 'asset.request_review',
                  entity: 'ASSET_KBUMN',
                  init_role: getProfile.value.role._id
                }
                if(modalValidasi.value) {
                  store.dispatch(Actions.APPROVAL_ASSETS, payloadValidasi)
                  .then((res) => {
                    router.push({ path: '/assets/list' });
                  })
                  submitButton.value?.setAttribute('data-kt-indicator', 'off')
                } else {
                  router.push({ path: '/assets/list' });
                }
                submitButton.value?.setAttribute('data-kt-indicator', 'off')
              })
              .catch(() => {
                disableButton.value = false
              })
            } else {
              press_manage.value = true
              submitButton.value?.setAttribute('data-kt-indicator', 'off')
              disableButton.value = false
              ElMessage({
                message: 'Tekan tombol hijau pada kolom aksi (✓ ) untuk menambahkan data pengelolaan',
                type: 'error',
              });
            }
          } else {
            if (forms.array_upaya_pemanfaatan.length > 0 && !show_form_upata.value) {
              store.dispatch(Actions.UPDATE_ASSETS, payloadIdle)
              .then((res) => {
                disableButton.value = false
                const payloadValidasi = {
                  user_id : getProfile.value._id,
                  entity_id: res.data._id,
                  action: 'ASSET.request_review',
                  entity: 'ASSET_KBUMN',
                  init_role: getProfile.value.role._id
                }
                if(modalValidasi.value) {
                  store.dispatch(Actions.APPROVAL_ASSETS, payloadValidasi)
                  .then((res) => {
                    router.push({ path: '/assets/list' });
                  })
                } else {
                  router.push({ path: '/assets/list' });
                }
                submitButton.value?.setAttribute('data-kt-indicator', 'off')
              })
              .catch(() => {
                disableButton.value = false
              })
            } else {
              press_manage_idle.value = true
              submitButton.value?.setAttribute('data-kt-indicator', 'off')
              disableButton.value = false
              ElMessage({
                message: 'Tekan tombol hijau pada kolom aksi (✓ ) untuk menambahkan data Upaya Pemanfaatan',
                type: 'error',
              });
            }
          }
        } else {
          if (polygonTemp.value.length === 0) {
            router.push(`/assets/update/${route.params.id}/informasi-assets#GHF`)
            ElMessage({
              message: 'Data Polygon masih kosong, Silakan tekan tombol "Update Koordinat atau Area di Map" untuk menambahkan Polygon',
              type: 'error',
            });
          } else {
            ElMessage({
              message: 'Masih terdapat field yang kosong atau isian tidak sesuai, Silahkan Cek Kembali',
              type: 'error',
            });
          }
          submitButton.value?.setAttribute('data-kt-indicator', 'off')
          disableButton.value = false
        }
      });
      disableButton.value = false
    };

    const getListCurrency = computed(() => {
      return store.getters.getListCurrency;
    });

    const addMapMarker = ref('') as any;
    const addMap = ref('') as any;
    const coordinate = ref({
      lat: '',
      long: '',
    }) as any;

    const getPoint = () => {
      if (addMapMarker.value.pinMarker.lat != '') {
        let lat = addMapMarker.value.pinMarker.lat;
        let lng = addMapMarker.value.pinMarker.lng;
        coordinate.value = {
          lat: lat,
          lng: lng,
        };

        tempCoordinate.value = lat + ',' + lng;
      }
      polygonTemp.value = addMapMarker.value.pinPolygon;
      polylineTemp.value = addMapMarker.value.pinPolyline;
    };

    const createPinPoint = (type, index = 0) => {
      let point = [] as any;
      let validatePin = { msg: '', state: true };
      if (type == 'marker') {
        tempCoordinate.value = tempCoordinate.value.replace(/\s+/g, '');
        point = tempCoordinate.value.split(',');
        validatePin = validationPin('marker', point);
      } else if (type == 'polygon') {
        polygonTemp.value[index] = polygonTemp.value[index].replace(/\s+/g, '');
        point = polygonTemp.value[index].split(',');
        validatePin = validationPin('polygon', point);
      } else {
        polylineTemp.value[index] = polylineTemp.value[index].replace(
          /\s+/g,
          ''
        );
        point = polylineTemp.value[index].split(',');
        validatePin = validationPin('polyline', point);
      }

      let lat = [] as any;
      let long = [] as any;
      let merged = [] as any;
      switch (validatePin.state) {
        case true:
          switch (type) {
            case 'marker':
              if (point[1]) {
                if (point.length > 0) {
                  addMapMarker.value.pinMarker = {
                    lat: point[0],
                    lng: point[1],
                  };

                  addMap.value.addMarker('marker', [point[0], point[1]]);
                  addMapMarker.value.addMarker('marker', [point[0], point[1]]);
                }
              }
              break;
            case 'polygon':
              point.forEach((str, index) => {
                if (index % 2 == 0) {
                  lat.push(str);
                }
                if (index % 2) {
                  long.push(str);
                }
              });

              for (let i = 0; i < lat.length; i++) {
                merged.push([lat[i], long[i]]);
              }
              polygonTemp.value[index] = merged;
              addMapMarker.value.addMarker('polygon', [merged]);
              addMap.value.addMarker('polygon', [merged]);
              addMapMarker.value.pinPolygon = [merged];
              addMap.value.pinPolygon = [merged];
              break;
            case 'polyline':
              point.forEach((str, index) => {
                if (index % 2 == 0) {
                  lat.push(str);
                }
                if (index % 2) {
                  long.push(str);
                }
              });

              for (let i = 0; i < lat.length; i++) {
                merged.push([lat[i], long[i]]);
              }
              polylineTemp.value[index] = merged;
              addMapMarker.value.addMarker('polyline', [merged]);
              addMap.value.addMarker('polyline', [merged]);
              addMapMarker.value.pinPolyline = [merged];
              addMap.value.pinPolyline = [merged];
              break;
            default:
              console.log('not spesific pin type');
          }
          break;
        default:
          switch (type) {
            case 'marker':
              tempCoordinate.value = '';
              break;
            case 'polygon':
              polygonTemp.value[index] = '';
              break;
            case 'polyline':
              polylineTemp.value[index] = '';
              break;
            default:
              console.log('not spesific pin type');
          }
          mapLocation.value = false;
          ElMessage({
            message: validatePin.msg,
            type: 'warning',
          });
      }
    };

    let mapLocation = ref(false);
    const centerCoordinate = ref({});

    const invalidPolygon = () => {
      ElMessage({
        message: 'Anda belum memasukan Polygon, Harap masukan Polygon terlebih dahulu.',
        type: 'error',
      });
    }

    const polygoneBeforeValue = ref([]) as any

    const cancelEditMap = () => {
      mapLocation.value = false
    }

    const openKoordinat = () => {
      polygonTemp.value = polygonTemp.value.filter((letter) => letter !== "")
      const temp = tempCoordinate.value.split(',');
      let lang = [temp[0], temp[1]];

      if (mapLocation.value) {
        mapLocation.value = false;
        // addMap.value.getCenterMap();
        addMap.value.center = addMapMarker.value.center;
        // addMap.value.refreshMap();
        addMapMarker.value.selectedPin = { lat: '', lng: '', label: '' };
        addMapMarker.value.pinPoint = '';

        if (tempCoordinate.value != '') {
          addMap.value.addMarker('marker', lang);
          addMap.value.addMarker('polygon', polygonTemp);
          addMap.value.addMarker('polyline', polylineTemp);
        }
      } else {
        mapLocation.value = true;
        if (addMap.value.center[0] != '') {
          addMapMarker.value.center = addMap.value.center;
          addMapMarker.value.refreshMap();
        }
        if (tempCoordinate.value != '') {
          addMapMarker.value.addMarker('marker', lang);
          addMapMarker.value.addMarker('polygon', polygonTemp);
          addMapMarker.value.addMarker('polyline', polylineTemp);
        }
      }
    };

    const removePoint = (index, type) => {
      if (type == 'polygon') {
        polygonTemp.value.splice(index, 1);
      }

      if (type == 'polyline') {
        polylineTemp.value.splice(index, 1);
      }

      // addMapMarker.value.removeMarker(index, type);
      /**
       * Refresh Map
       */
      const temp = tempCoordinate.value.split(',');
      let lang = [temp[0], temp[1]];
      addMap.value.refreshMap();
      addMapMarker.value.refreshMap();
      if (tempCoordinate.value != '') {
        addMap.value.addMarker('marker', lang);
        addMap.value.addMarker('polygon', polygonTemp);
        addMap.value.addMarker('polyline', polylineTemp);

        addMapMarker.value.addMarker('marker', lang);
        addMapMarker.value.addMarker('polygon', polygonTemp);
        addMapMarker.value.addMarker('polyline', polylineTemp);
      }
    };

    let tempArrPhoto = reactive({}) as any;
    let tempArrDoc = reactive({}) as any;

    const getArrayPhoto = (val) => {
      tempArrPhoto = val;
    };
    const getArrayDoc = (val) => {
      tempArrDoc = val;
    };

    const initMap = () => {
      let payload = [forms.coordinate.lat, forms.coordinate.long];
      addMap.value.center = payload;
      addMapMarker.value.center = payload;
      addMap.value.refreshMap();
      addMapMarker.value.refreshMap();

      addMapMarker.value.pinPolygon = polygonTemp.value;
      addMapMarker.value.pinPolyline = polylineTemp.value;
      addMap.value.addMarker('polygon', polygonTemp);
      addMap.value.addMarker('polyline', polylineTemp);
    };

    const press_manage = ref(false)
    const press_manage_idle = ref(false)

    const addPinMarker = (payload) => {
      switch (payload) {
        case 'polygon':
          polygonTemp.value.push('');
          break;
        default:
          polylineTemp.value.push('');
      }
    };

    onBeforeMount(() => {
      const route = router.currentRoute.value.params.id;
      // if (addMap != undefined) { addMap.remove(); }

      // sementara hardcode
      store.dispatch(Actions.GET_PROVINCE_LIST);
      store.dispatch(Actions.GET_CHANNEL_LIST);
      store.dispatch(
        Actions.GET_STATUS_LEGAL_ASSETS,
        '6316769d8c130d3fa041611c'
      );
      store.dispatch(
        Actions.GET_STATUS_TANAH_ASSETS,
        '631689248c130d3fa0416184'
      );
      store.dispatch(
        Actions.GET_STATUS_PEROLEHAN_LIST,
        '6316f26a8c130d3fa04163c7'
      );
      store.dispatch(Actions.GET_KETERANGAN_ASSETS, '6317d3195f05cc92cf2d5540');
      store.dispatch(
        Actions.GET_STATUS_PRODUKTIVITAS,
        '6317d89a5f05cc92cf2d5572'
      );
      store.dispatch(Actions.GET_USER_ASSETS, '6317dd3e5f05cc92cf2d5596');
      store.dispatch(
        Actions.GET_STATUS_PENGELOLAAN,
        '6317df375f05cc92cf2d55ce'
      );
      store.dispatch(Actions.GET_UPAYA_PEMANFAATAN, '6317e2b55f05cc92cf2d5607');
      store.dispatch(Actions.GET_CURRENCY);
      store.dispatch(
        Actions.GET_ASSET_CATEGORY_LIST,
        '631577ec8c130d3fa0415fe8'
      );

      store.dispatch(Actions.GET_DETAIL_ASSETS, route).then((res) => {
        const dataRes = res?.data;
        /**
         * init kordinat lokasi
         */
        forms.coordinate.lat = dataRes?.asset_location.geolocation.coordinate.lat
        forms.coordinate.long = dataRes?.asset_location.geolocation.coordinate.long
        
        var found = false
        var foundPolyline = false
        // check polygon multidimention
        for (var i = 0; i < dataRes?.asset_location.geolocation.polygon.length; i++) {
          if (!Array.isArray(dataRes?.asset_location.geolocation.polygon[i])) {
            found = true
            break;
          }
        }
        if (dataRes?.asset_location.geolocation.polygon.length > 0) {
          forms.polygon = found ? [dataRes?.asset_location.geolocation.polygon] : dataRes?.asset_location.geolocation.polygon
        } else {
          forms.polygon = []
        }

        // check polyline multidimention
        for (var x = 0; x < dataRes?.asset_location.geolocation.polyline.length; x++) {
          if (!Array.isArray(dataRes?.asset_location.geolocation.polyline[x])) {
            foundPolyline = true
            break;
          }
        }
        if (dataRes?.asset_location.geolocation.polyline.length > 0) {
          forms.polyline = foundPolyline ? [dataRes?.asset_location.geolocation.polyline] : dataRes?.asset_location.geolocation.polyline
        } else {
          forms.polyline = []
        }

        forms.province_id = dataRes?.asset_location.province._id;
        forms.regency_id = dataRes?.asset_location.regency._id;
        forms.district_id = dataRes?.asset_location.district._id;
        forms.village_id = dataRes?.asset_location.village._id;
        forms.address = dataRes?.asset_location.address;

        getRegencyById(forms.province_id)
        getDistrictById(forms.regency_id)
        getVillageById(forms.district_id)

        tempCoordinate.value =
          dataRes?.asset_location.geolocation.coordinate.lat === ''
            ? ''
            : dataRes?.asset_location.geolocation.coordinate.lat.replace(/\s+/g, '').replace('lat:', '') +
              ',' +
              dataRes?.asset_location.geolocation.coordinate.long.replace(/\s+/g, '').replace('long:', '');
        // polygonTemp.value = dataRes.geolocation.polygon.length > 0 ? dataRes.geolocation.polygon : [];
        // polylineTemp.value = dataRes.geolocation.polyline.length > 0 ? dataRes.geolocation.polyline : [];

        let polygonData = forms.polygon
        if (polygonData.length > 0) {
          let polygonLang = [] as any;
          polygonData.forEach((x: any) => {
            var tempolygonPin = [] as any;
            if (Array.isArray(x)) {
              x.forEach((y) => {
                tempolygonPin.push([y['lat'], y['long']]);
              });
              polygonLang.push(tempolygonPin);
            } else {
              if (x.long != null) {
                polygonLang.push([x.lat, x.long]);
              }
            }
          });

          polygonTemp.value = polygonLang;
        }

        polygoneBeforeValue.value = polygonData

        let polylineData = forms.polyline
        if (polylineData.length > 0) {
          let polylineLang = [] as any;
          polylineData.forEach((x: any) => {
            var tempolylinePin = [] as any;
            if (Array.isArray(x)) {
              x.forEach((y) => {
                tempolylinePin.push([y['lat'], y['long']]);
              });
              polylineLang.push(tempolylinePin);
            } else {
              if (x.long != null) {
                polylineLang.push([x.lat, x.long]);
              }
            }
          });

          polylineTemp.value = polylineLang;
        }

        setCurrentPageBreadcrumbs(dataRes.name, ['Update Aset']);
        const listNilaiBuku = dataRes?.asset_historical_value.filter((x) => {
          return x.type === 0;
        });
        const listNilaiRevaluasi = dataRes?.asset_historical_value.filter((x) => {
          return x.type === 1;
        });
        const listArrNilaiBuku: any[] = [];
        listNilaiBuku.map((s, i) => {
          return listArrNilaiBuku.push({
            year: s.historical_year,
            currency_id: s.currency._id,
            value: s.value,
            type: 'Nilai Buku',
            id: i++
          });
        });

        forms.nilai_buku = listArrNilaiBuku;

        const listArrNilaiRevaluasi: any[] = [];
        listNilaiRevaluasi.map((s, i) => {
          listArrNilaiRevaluasi.push({
            year: s.historical_year,
            currency_id: s.currency._id,
            value: s.value,
            type: 'Nilai Revaluasi',
            id: i++
          });
        });
        forms.nilai_revaluasi = listArrNilaiRevaluasi;
        
        tempNilai.value = listArrNilaiBuku
        tempRevaluasi.value = listArrNilaiRevaluasi

        forms.id = dataRes._id;
        forms.name = dataRes.name;
        forms.external_code = dataRes.external_code;
        forms.type = dataRes.type._id;
        forms.acquisition_status = dataRes.acquisition_status;
        forms.acquisition_value_rp = dataRes.acquisition_value_rp;
        forms.acquisition_value_usd = dataRes.acquisition_value_usd;
        store
          .dispatch(Actions.GET_DETAIL_CHANNEL, dataRes?.channel._id)
          .then((res) => {
            forms.channel_id = res?.data;
          });

        forms.acquisition_date = dataRes.acquisition_date
        forms.status_tanah = dataRes?.asset_ownership.status._id;
        forms.document_code = dataRes.asset_ownership.document_code;
        forms.document_code_support =
          dataRes.asset_ownership.document_code_support;
        forms.atas_nama = dataRes.asset_ownership.atas_nama;
        forms.start_date_certificate =
          dataRes.asset_ownership.start_date_certificate;
        forms.end_date_certificate =
          dataRes.asset_ownership.end_date_certificate;
        forms.issued_by = dataRes.asset_ownership.issued_by;
        forms.luas_total = dataRes.asset_ownership.luas_total.value;
        forms.surat_ukur = dataRes.asset_ownership.surat_ukur;

        if (dataRes?.asset_administration !== null) {
          forms.peruntukan = dataRes?.asset_administration.peruntukan;
          forms.status_legalitas =
            dataRes.asset_administration.status_legalitas._id;
          forms.luas_status = dataRes.asset_administration.luas_status
            ? dataRes.asset_administration.luas_status.value
            : '';
          forms.status_produktivitas =
            dataRes.asset_administration.status_produktivitas._id;
        }

        show_form_upata.value = false
        
        if (dataRes?.data_pengelolaan === null || dataRes?.data_pengelolaan.length === 0) {
          dataRes?.upaya_pemanfaatan.map((s) => {
            forms.array_upaya_pemanfaatan.push({
              upaya_pemanfaatan: s.upaya_pemanfaatan._id === '631689f28c130d3fa0416197' ? '6417f7d4330da1732e9c3f81' : s.upaya_pemanfaatan._id,
              luas_pemanfaatan: s.luas_pemanfaatan.value,
              luas_pemanfaatan_uom_id: "6317e5975f05cc92cf2d562a",
              keterangan_pemanfaatan: s.keterangan_pemanfaatan !== undefined ? s.keterangan_pemanfaatan : '-',
            });
          });
        }

        if (dataRes?.asset_administration.keterangan_aset !== null) {
          forms.keterangan_aset =
            dataRes?.asset_administration.keterangan_aset.map((s) => {
              return s._id;
            });
        }

        if (dataRes.data_pengelolaan !== null) {
          dataRes?.data_pengelolaan.map((s) => {
            forms.data_pengelolaan.push({
              nomor_surat : s.nomor_surat,
              pengguna : s.pengguna,
              nama_pengguna : s.nama_pengguna,
              status_pengelolaan : s.status_pengelolaan,
              luas_penggunaan : s.luas_penggunaan
            });
          });
        }

        addProduktif.value = false

        const getAllDoc: any[] = [];
        
        if (dataRes?.asset_attachment) {
          // Mendefinisikan ekstensi yang diizinkan
          const imageExtensions = ['jpeg', 'jpg', 'png', 'webp'];
          const documentExtensions = ['docx', 'xlsx', 'pdf', 'xls', 'rar', 'xml', 'csv'];
          
          // Menambahkan semua dokumen ke dalam getAllDoc
          dataRes.asset_attachment.forEach((s) => {
            getAllDoc.push({
              url: s.url,
              filename: s.filename,
              extension: s.extension,
              filesize: s.size,
              size_unit: 'Kilobyte',
              utama: s.utama,
            });
          });

          forms.attachment = getAllDoc;

          // Memilah dokumen berdasarkan ekstensi
          const listFoto = getAllDoc.filter((x) => imageExtensions.includes(x.filename.split('.').pop().toLowerCase()));
          const listDokumen = getAllDoc.filter((x) => documentExtensions.includes(x.filename.split('.').pop().toLowerCase()));

          console.log('listDokumen', listDokumen);

          form.attachmentFoto = listFoto;
          form.attachmentDokumen = listDokumen;
        } else {
          form.attachmentDokumen = [];
        }


        // seperateFile(dataRes);
        initMap();
      });

      // const seperateFile = (arr) => {
      //   const data = arr;
      //   if (data.data_pengelolaan !== null) {
      //     forms.data_pengelolaan = data.data_pengelolaan;
      //     idKelola = forms.data_pengelolaan.length;
      //     addProduktif.value = false;
      //   } else {
      //     addProduktif.value = true;
      //   }
      // };
    });

    const validationPin = (type, payload) => {
      let res = { msg: '', state: true };
      if (payload.length % 2 > 0) {
        res = {
          msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
          state: false,
        };
      }
      if (type == 'marker' && payload.length < 2) {
        res = {
          msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
          state: false,
        };
      }
      if (type === 'marker' && payload.length > 2) {
        res = {
          msg: 'Nilai area koordinat lokasi tidak valid, mohon periksa kembali',
          state: false,
        };
      }
      if ((type === 'polyline' || type === 'polygon') && payload.length < 4) {
        res = {
          msg: 'Nilai area koordinat lokasi tidak lengkap, mohon periksa kembali',
          state: false,
        };
      }
      return res;
    };

    const namePengguna = (val) => {
      const temp = getListPengguna.value.filter(function (x) {
        return x._id === val;
      });
      return temp[0];
    };

    const namePengelolaan = (val) => {
      const temp = getListPengelolaan.value.filter(function (x) {
        return x._id === val;
      });
      return temp[0];
    };

    const namaUpaya = (val) => {
      const temp = getListUpayaPemanfaatan.value.filter(function (x) {
        return x._id === val
      })
      return temp[0]
    }

    const syncTempCoordinate = () => {
      const temp = tempCoordinate.value.split(',');
      forms.coordinate.lat = temp[0];
      forms.coordinate.long = temp[1];
    }
    const assetValidasi = imagesAsset.assetValidasi

    const cancelEvent = () => {
      console.log('cancel!')
    }

    const mapZoom = ref(7)

    const reCenterMapByLocationName = async (range='province') => {
      var search = ''
      if (range == 'village' && forms.village_id.length > 1) {
        search = getVillage.value.find((el) => el._id == forms.village_id).name
        mapZoom.value = 15
      }
      else if (range == 'district' && forms.district_id.length > 1) {
        forms.village_id = ''
        search = getDistrict.value.find((el) => el._id == forms.district_id).name
        mapZoom.value = 12
      }
      else if (range == 'regency' && forms.regency_id.length > 1) {
        forms.village_id = ''
        forms.district_id = ''
        search = getRegency.value.find((el) => el._id == forms.regency_id).name
        mapZoom.value = 10
      }
      else if (range == 'province' && forms.province_id.length > 1) {
        forms.regency_id = ''
        forms.district_id = ''
        forms.village_id = ''
        search = getProvince.value.find((el) => el._id == forms.province_id).name
        mapZoom.value = 9
      } else search = "DKI JAKARTA"
      searchOpenStreet(search)
    };

    const searchOpenStreet = async (query) => {
      const provider = new OpenStreetMapProvider({
        params: {
          "accept-language": "id",
          countrycodes: "id", // limit search results to the Indonesia
          addressdetails: 1, // include additional address detail parts
        },
      });
      let results = await provider.search({ query });
      const foundCoordinate = results[0];
      
      if (foundCoordinate) {
        centerCoordinate.value = {
          lat: foundCoordinate?.y.toString(),
          lng: foundCoordinate?.x.toString()
        }
        addMap.value.setCenterMap(centerCoordinate.value, mapZoom.value);
      }
    }

    onMounted (() => {
      initMap()
      if(route.query?.polygon === '0') {
        setTimeout(() => {
          openKoordinat()
        }, 500);
      }
    })

    return {
      t,
      images,
      forms,
      form,
      dataDocument,
      cancelEvent,
      namePengguna,
      namePengelolaan,
      formatNumber,
      formatFileExt,
      formatDate,
      formatSize,
      parseNumber,
      tempNilaiRevaluasi,
      tempNilaiBuku,
      addProduktif,
      actProduktif,
      nilaiStatusPerolehan,
      getArrayPhoto,
      getArrayDoc,
      tempArrPhoto,
      tempArrDoc,
      btnRed,
      mapPoint,
      polygon,
      polyline,
      polygonTemp,
      polylineTemp,
      trash,
      btnGreen,
      buttonSave,
      btnCancel,
      addPhoto,
      //   v-model
      province,
      regency,
      district,
      village,
      channel,
      street,
      submitButton,
      drawerNilai,
      //
      dialogVisible,
      dialogVisibleDoc,
      pointmapInput,
      polygonInput,
      polylineInput,
      btnEdit,
      btnDelete,
      getProvince,
      getRegency,
      getVillage,
      getDistrict,
      getChannel,
      formatPrice,
      //
      getRegencyById,
      getDistrictById,
      getVillageById,
      typeDoc,
      submitAssets,
      disableButton,
      checkClear,
      disableDate,
      getJenisAset,
      getLegalStatus,
      getStatusTanah,
      getStatusPerolehan,
      getKeteranganAset,
      delPengelolaan,
      keterangan,
      getListProduktivitas,
      getListPengguna,
      getListPengelolaan,
      disabledForm,
      getListUpayaPemanfaatan,
      NumbersOnly,
      getPoint,
      addMapMarker,
      addMap,
      createPinPoint,
      coordinate,
      actEditPengelolaan,
      idPengelolaan,
      tempCoordinate,
      years,
      selectYear,
      getListCurrency,
      currencyRevaluation,
      currencyBook,
      dataBook,
      dataRevaluation,
      flagId,
      disableYear,
      closeDrawer,
      removeImage,
      openDrawer,
      pushNilaiData,
      delListAssetValue,
      delDocValue,
      editDataNilai,
      openEditDataNilai,
      openEditDataRevaluasi,
      tempData,
      selectedFile,
      editManage,
      selectedFileDoc,
      counterImage,
      counterDoc,
      getRootProps,
      getInputProps,
      isImgUrl,
      documentName,
      PhotoName,
      tempFile,
      tempPhoto,
      iconPdf,
      asetFile,
      iconXlsx,
      iconDocx,
      data_pengelolaan,
      editPengelolaan,
      nomor_surat,
      pengguna,
      nama_pengguna,
      status_pengelolaan,
      rules,
      dateMandatory,
      luas_penggunaan,
      formRef,
      formRefNilai,
      add,
      mapLocation,
      centerCoordinate,
      openKoordinat,
      cancelEditMap,
      invalidPolygon,
      removePoint,
      addPinMarker,
      modalValidasi,
      AjukanValidasi,
      assetValidasi,
      ...rest,
      syncTempCoordinate,
      alertDataNilaiRevaluasi,
      alertDataNilai,
      tempNilai,
      tempRevaluasi,
      actionDataValue,
      deleteDataValue,
      disable_add_efforts,
      add_efforts,
      show_form_upata,
      add_field_effort,
      btnClose,
      delUpaya,
      editUpaya,
      actEditUpaya,
      editManageUpaya,
      idUpaya,
      namaUpaya,
      validCoor,
      press_manage,
      press_manage_idle,
      reCenterMapByLocationName,
      ruleForm,
      rulesDataNilai,
      handleDoubleClick,
      isButtonDisabled
    };
  },
});
