
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { tagDefineColor, dateFormatSeconds, capitalize } from '@/core/helpers/helps';
import { ActionsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';

  export default {
    name: "history-asset",
    props: {
      search: {
        required: false,
        default: '',
        type: String,
      }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const loading = computed(() => store.getters.LoadingApprovalHistory);
        const payload = computed(() => store.getters.ApprovalHistory);
        const assetSelected = computed(() => store.getters.getAssetSelected);
        const currentPage = computed(() => store.getters.ApprovalHistoryParams.page_num)
        const pageSize = computed(() => store.getters.ApprovalHistoryParams.data_limit)
        const dataTotal = computed(() => store.getters.ApprovalHistoryParams.total_data)
        const params = computed(() => store.getters.ApprovalHistoryParams)
        const search = computed(() => props.search);

        const fetchData = (params:any) => {
            if (params.value?.asset_id !== '') {
                store.dispatch(ActionsAdminValidasi.GET_APPROVAL_HISTORY, {
                    asset_id : params.value?.asset_id,
                    ...params
                })
            } 
        }

        const handleSizeChange = (val: number) => {
            fetchData({
                ...assetSelected.value,
                limit : val
            })
        }
        const handleCurrentChange = (val: number) => {
            fetchData({
                ...assetSelected.value,
                page : val
            })
        }

        watch(search, (cr: any, pv) => {
            if (params.value?.asset_id !== '') {
                store.dispatch(ActionsAdminValidasi.GET_APPROVAL_HISTORY, {
                    asset_id : params.value?.asset_id,
                    search : cr
                })
            } 
        })

      return {
        close,
        t,
        payload,
        tagDefineColor,
        dateFormatSeconds,
        capitalize,
        fetchData,
        store,
        ActionsAdminValidasi,
        loading,
        assetSelected,
        currentPage,
        pageSize,
        dataTotal,
        handleSizeChange,
        handleCurrentChange
      };
    },
  };
  