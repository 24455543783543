
import { computed, onMounted, ref, watch } from 'vue';
import { ElDialog } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { ActionsAdminValidasi, MutationsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import ApprovalHistoryTable from "@/components/table/ApprovalHistoryTable.vue";
import ActivityAssetHistoryTable from "@/components/table/ActivityAssetHistoryTable.vue";
import { useStore } from 'vuex';
import { capitalize } from '@/core/helpers/helps';

  export default {
    name: "history-asset",
    components :{
        ElDialog,
        ApprovalHistoryTable,
        ActivityAssetHistoryTable
     },
    props: {
      open: {
        required: true,
        default: false,
        type: Boolean,
      },
      detailAset:{
        required : true,
        type : Object
      }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const searchParams = ref('');
        const payload = computed(() => store.getters.getAssetSelected);
        const activeName = ref('activity')
        const activeLabel = ref(t("pageListAssets.modalHistory.tabs.activityHistory"))

        const close = () => {
            store
                .dispatch(ActionsAdminValidasi.OPEN_MODAL_HISTORY, !store.getters.ModalHistory)
        };

        const tabChange = (e) => {
          console.log(e, payload.value);
          const { props } = e
          if (props.name === 'activity') {
            store.dispatch(ActionsAdminValidasi.GET_ACTITVITY_HISTORY, payload.value)
          } else {
            store.dispatch(ActionsAdminValidasi.GET_APPROVAL_HISTORY, payload.value._id)
          }
          activeLabel.value = props.label
        }

      return {
        close,
        t,
        capitalize,
        searchParams,
        tabChange,
        activeName,
        activeLabel,
        payload
      };
    },
  };
  