
import { defineComponent, ref } from "vue";
import NeedApproval from "@/components/pages/approval/tab/NeedApproval.vue";
import History from "@/components/pages/approval/tab/History.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "components-pages-approval-list",
  components: {
    NeedApproval,
    History,
  },
  setup() {
    const route = useRoute();
    const currentTab = ref("need-approval");

    return {
      currentTab,
      route,
    };
  },
});
