
import {
  defineComponent,
  inject,
  onMounted,
  onBeforeMount,
  ref,
  reactive,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { ActionsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@/store/enums/StoreEnums';
import moment from 'moment';
import LeafletMap from '@/components/leaflet/Leaflet.vue';
// import PdfPreview from '@/components/pages/assets/PdfPreview.vue';
import { checkAccess, tagDefineColor } from '@/core/helpers/helps';
import Error403 from '../crafted/authentication/Error403.vue';
import { watch } from 'fs';

export default defineComponent({
  name: 'List of Assets',
  components: { 
      LeafletMap, 
      Error403
  },
  setup() {
    const { t } = useI18n();
    const images = inject('$images');
    const router = useRouter();
    const store = useStore();
    const openPreviewImage = ref(false);
    const openPreviewPDF = ref(false);
    const srcListImage : any = ref([]);
    const getDetailAsset = computed(() => {
      return store.getters.detailAssets;
    });
    const fileDetail:any = ref({});

    const setPreviewImage = () => {
      openPreviewImage.value = !openPreviewImage.value
    }

    const setPreviewPDF = (payload:any) => {
      if (payload?.extension && payload.extension !== 'application/pdf') {
        return '';
      }

      let url = payload.url.split('http://').length >= 2 ? `https://${payload.url.split('http://')[1]}` : payload.url
      
      fileDetail.value = {
        ...payload,
        url : url
      };

      return url;
    }

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const formatFileExt = (value) => {
      let extension = value.split('.').pop();
      return extension.toUpperCase();
    };

    const formatDate = (value) => {
      return moment(value).format('DD MMMM YYYY');
    };

    const formatSize = (value) => {
      return Math.ceil(value);
    };
    const datareal = ref() as any

    const forms = reactive({
      name: '',
      luas: '',
      kode: '',
      lokasi: '',
      point: '',
      polyline: '',
      polygon: '',
      region: '',
      date: '',
      status: '',
      price: '',
      delivery: false,
      type: [],
      resource: '',
      desc: '',
    });

    var newForm = ref({
      _id: '',
      name: '',
      code: '',
      external_code: '',
      acquisition_date: '',
      acquisition_value_rp: 0,
      acquisition_value_usd: 0,
      acquisition_status: {
        _id: '',
        name: '',
        description: '',
        code: '',
        is_active: true,
        status_category_id: '',
        created_at: '',
        updated_at: '',
      },
      type: {
        _id: '',
        name: '',
        description: '',
        code: '',
        is_active: true,
        status_category_id: '',
        created_at: '',
        updated_at: '',
      },
      approval_status: {
        _id: '',
        description: '',
        is_active: true,
        created_at: '',
        updated_at: '',
      },
      is_new: true,
      channel_id: '',
      created_at: '',
      updated_at: '',
      geolocation: {
        _id: '',
        asset_info_id: '',
        coordinate: {
          lat: '',
          long: '',
          _id: '',
        },
        polyline: [[{ lat: '', long: '' }]],
        polygon: [[{ lat: '', long: '' }]],
        created_at: '',
        updated_at: '',
      },
      location: {
        _id: '',
        asset_info_id: '',
        address: '',
        province_id: '',
        regency_id: '',
        district_id: '',
        village_id: '',
        created_at: '',
        updated_at: '',
        province: {
          _id: '',
          name: '',
          created_at: '',
          updated_at: '',
        },
        regency: {
          _id: '',
          name: '',
          province_id: '',
          created_at: '',
          updated_at: '',
        },
        district: {
          _id: '',
          name: '',
          regency_id: '',
          created_at: '',
          updated_at: '',
        },
        village: {
          _id: '',
          name: '',
          district_id: '',
          created_at: '',
          updated_at: '',
        },
      },
      asset_ownership: {
        _id: '',
        atas_nama: '',
        start_date_certificate: '',
        end_date_certificate: '',
        document_code: '',
        document_code_support: '',
        issued_by: '',
        luas_total: '',
        surat_ukur: '',
        asset_info_id: '',
        status: {
          _id: '',
          __v: 0,
          parent_status: {
            _id: '',
            name: '',
            is_multiple_choice: false,
            description: '',
            code: '',
            is_active: false,
            channel_id: '',
            created_at: '',
            updated_at: '',
            __v: 0,
          },
          child_status: {
            _id: '',
            name: '',
            description: '',
            code: '',
            is_active: false,
            status_category_id: '',
            created_at: '',
            updated_at: '',
            __v: 0,
          },
        },
        created_at: '',
        updated_at: '',
        asset_dimension: {
          _id: '',
          dimension_category_id: '',
          dimension_uom_id: '',
          value: 0,
          created_at: '',
          updated_at: '',
          parent_asset_dimension: {
            _id: '',
            name: '',
            description: '',
            code: '',
            is_active: true,
            channel_id: '',
            created_at: '',
            updated_at: '',
          },
          child_asset_dimension: {
            _id: '',
            name: '',
            long_name: '',
            description: '',
            code: '',
            is_active: true,
            dimension_category_id: '',
            created_at: '',
            updated_at: '',
          },
        },
      },
      asset_administration: [
        {
          _id: '',
          asset_info_id: '',
          status_legalitas: '',
          keterangan_aset: null,
          peruntukan: '',
          luas_status: '',
          status_produktivitas: '',
          created_at: '',
          updated_at: '',
        },
      ],
      asset_administration_keterangan_aset: [],
      asset_administration_keterangan_aset_category_value: [],
      asset_administration_status_legalitas: {
        _id: '',
        asset_info_id: '',
        status_category_id: '',
        status_category_value_id: '',
        created_at: '',
        updated_at: '',
      },
      asset_administration_status_legalitas_category_value: {
        _id: '',
        name: '',
        description: '',
        code: '',
        is_active: true,
        status_category_id: '',
        created_at: '',
        updated_at: '',
      },
      asset_administration_luas_status: {
        _id: '',
        asset_info_id: '',
        dimension_category_id: '',
        dimension_uom_id: '',
        value: '',
        created_at: '',
        updated_at: '',
      },
      asset_administration_luas_status_category_value: {
        _id: '',
        name: '',
        long_name: '',
        description: '',
        code: '',
        is_active: true,
        dimension_category_id: '',
        created_at: '',
        updated_at: '',
      },
      asset_administration_status_produktivitas: {
        _id: '',
        asset_info_id: '',
        status_category_id: '',
        status_category_value_id: '',
        created_at: '',
        updated_at: '',
      },
      asset_administration_status_produktivitas_category_value: {
        _id: '',
        name: '',
        description: '',
        code: '',
        is_active: true,
        status_category_id: '',
        created_at: '',
        updated_at: '',
      },
      data_upaya_pemanfaatan: {
        asset_dimension: {
          value: '',
          child_asset_dimension: {
            name: '',
          },
        },
        keterangan_pemanfaatan: '',
        upaya_pemanfaatan: {
          category: {
            name: '',
          },
        },
      },
      data_pengelolaan: [
        {
          _id: '',
          asset_info_id: '',
          nomor_surat: '',
          nama_pengguna: '',
          created_at: '',
          updated_at: '',
        },
      ],
      data_pengelolaan_pengguna: [],
      data_pengelolaan_pengguna_child_status: [],
      data_pengelolaan_pengguna_parent_status: [],
      data_pengelolaan_asset_status: [],
      data_pengelolaan_asset_status_child_status: [],
      data_pengelolaan_asset_status_parent_status: [],
      data_pengelolaan_asset_dimension: [],
      data_pengelolaan_asset_dimension_parent_asset_dimension: [],
      data_pengelolaan_asset_dimension_child_asset_dimension: [],
      historical_value: [
        {
          _id: '',
          asset_info_id: '',
          historical_year: 0,
          currency_id: '',
          value: 0,
          type: 0,
          created_at: '',
          updated_at: '',
        },
      ],
      attachment: [
        {
          _id: '',
          asset_info_id: '',
          asset_document_type_id: '',
          url: '',
          filename: '',
          extension: '',
          size: '',
          created_at: '',
          updated_at: '',
        },
        {
          _id: '',
          asset_info_id: '',
          asset_document_type_id: '',
          url: '',
          filename: '',
          extension: '',
          size: '',
          created_at: '',
          updated_at: '',
        },
      ],
    });

    const form : any = ref({
      channel_id: '',
      acquisition_date: '',
      attachmentFoto: [],
      attachmentDokumen: [],
      asset_ownership: {
        start_date_certificate: '',
        end_date_certificate: '',
      },
      data_pengelolaan: [
        {
          asset_info_id: '',
          luas_penggunaan: '',
          nama_pengguna: '',
          nomor_surat: '',
          pengguna: '',
          status_pengelolaan: '',
        },
      ],
      historical_value: [
        {
          historical_date: '',
          currency_id: '',
          value: '',
        },
        {
          historical_date: '',
          currency_id: '',
          value: '',
        },
      ],
      nilai_buku: [
        {
          id: '',
          historical_year: '',
          currency: '',
          value: '',
        },
      ],
      nilai_revaluasi: [
        {
          id: '',
          historical_year: '',
          currency: '',
          value: '',
        },
      ],
    });
    const checked1 = ref(true);
    const checked2 = ref(false);
    const radio2 = ref('1');
    // payload component form informasi
    const country = ref('');
    onMounted(() => {
      store.dispatch(ActionsAdminValidasi.GET_APPROVAL_STATUS);

      if (!checkAccess('assetdetail')) {
        router.push({
        path: '/404',
        });
      }
    })

    const polygonData = ref([])
    const polylineData = ref([])

    onBeforeMount(async () => {
      const route = router.currentRoute.value.params.id;
      store.dispatch(Actions.GET_DETAIL_ASSETS, route).then((res) => {
        const dataRes = res?.data;
        datareal.value = res?.data

        var found = false
        var foundPolyline = false
        // check polygon multidimention
        for (var i = 0; i < dataRes?.asset_location.geolocation.polygon.length; i++) {
          if (!Array.isArray(dataRes?.asset_location.geolocation.polygon[i])) {
            found = true
            break;
          }
        }

        if (dataRes?.asset_location.geolocation.polygon.length > 0) {
          polygonData.value = found ? [dataRes?.asset_location.geolocation.polygon] : dataRes?.asset_location.geolocation.polygon
        } else {
          polygonData.value = []
        }

        // check polyline data

        for (var x = 0; x < dataRes?.asset_location.geolocation.polyline.length; x++) {
          if (!Array.isArray(dataRes?.asset_location.geolocation.polyline[x])) {
            foundPolyline = true
            break;
          }
        }
        if (dataRes?.asset_location.geolocation.polyline.length > 0) {
          polylineData.value = foundPolyline ? [dataRes?.asset_location.geolocation.polyline] : dataRes?.asset_location.geolocation.polyline
        } else {
          polylineData.value = []
        }

        const listNilaiBuku = datareal.value.asset_historical_value.filter((x) => {
          return x.type === 0;
        });
        const listNilaiRevaluasi = datareal.value.asset_historical_value.filter((x) => {
          return x.type === 1;
        });
        const listArrNilaiBuku: any[] = [];
        const listArrNilaiRevaluasi: any[] = [];
        listNilaiBuku.map((s) => {
          listArrNilaiBuku.push({
            id: s._id,
            historical_year: s.historical_year,
            currency: s.currency._id,
            value: s.value,
          });
        });
        listNilaiRevaluasi.map((s) => {
          listArrNilaiRevaluasi.push({
            id: s._id,
            historical_year: s.historical_year,
            currency: s.currency._id,
            value: s.value,
          });
        });
        form.value.nilai_buku = listArrNilaiBuku;
        form.value.nilai_revaluasi = listArrNilaiRevaluasi;

        if (dataRes?.asset_attachment) {
          const imageExtensions = ['jpeg', 'jpg', 'png', 'webp'];
          const documentExtensions = ['docx', 'doc', 'xlsx', 'pdf', 'xls', 'rar', 'xml', 'csv'];

          const listFoto = dataRes.asset_attachment.filter((x) =>
            imageExtensions.includes(x.filename.split('.').pop().toLowerCase())
          );

          const listDokumen = dataRes.asset_attachment.filter((x) =>
            documentExtensions.includes(x.filename.split('.').pop().toLowerCase())
          );

          form.value.attachmentFoto = listFoto;
          form.value.attachmentDokumen = listDokumen;

          srcListImage.value = listFoto.map((item) => item.url);
        }


      });
    });
    const seperateFile = (arr) => {
      const data = arr;

      const getValue = (data, children) => {
        const res = data['data_pengelolaan'].map((firstLevel, index) => {
          return data[`data_pengelolaan_${children}`][index];
        });

        return res[0];
      };
      const getValueDimension = (data, children) => {
        const res = data['data_pengelolaan'].map((firstLevel, index) => {
          return data[`data_pengelolaan_${children}`];
        });

        return res[0];
      };
      const getValueId = (data, children) => {
        const res = data['data_pengelolaan'].map((firstLevel, index) => {
          return data[`data_pengelolaan_${children}`];
        });

        return res[0];
      };

      if (data.data_pengelolaan_asset_status_child_status.length > 0) {
        const final = data.data_pengelolaan.map((d, i) => {
          return (d = {
            ...d,
            status_pengelolaanx: getValue(data, 'asset_status_child_status')
              .name,
            status_pengelolaan: getValue(data, 'asset_status')
              .status_category_value_id,
            pengguna: getValue(data, 'pengguna').status_category_value_id,
            penggunax: getValue(data, 'pengguna_child_status').name,
            luas_penggunaan: getValueDimension(data, 'asset_dimension')[i]
              .value,
            luas_penggunaan_uom_id: '6317e0be5f05cc92cf2d5601',
          });
        });
        form.value.data_pengelolaan = final;
      }
    };
    const getListPengguna = computed(() => {
      return store.getters.getListPengguna;
    });
    const getListPengelolaan = computed(() => {
      return store.getters.getListPengelolaan;
    });
    const namePengguna = (val) => {
      const temp = getListPengguna.value.filter(function (x) {
        return x._id === val;
      });
      return temp[0];
    };

    const namePengelolaan = (val) => {
      const temp = getListPengelolaan.value.filter(function (x) {
        return x._id === val;
      });
      return temp[0];
    };

    const getApproval = computed(() => {
      return store.getters.ApprovalStatusList
    })

    const mapApproval = (payload: any) => {
      if (getApproval.value.length) {
        let appr = getApproval.value.filter((el) => el._id ===  payload.approval_status._id);
        if (appr.length > 0) {
          return appr[0].description;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const nameStatusApproval = (id: string) => {
      switch (id) {
        case '6333b254c352da91999f17bb':
          return t('pageListAssets.approval.name.initial')
        case '631ed357f97fb56ec2fcc0f0':
          return t('pageListAssets.approval.name.verified')
        case '631ed34af97fb56ec2fcc0ee':
          return t('pageListAssets.approval.name.unverified')
        case '631ed33bf97fb56ec2fcc0ec':
          return t('pageListAssets.approval.name.invalid')
        case '631ed318f97fb56ec2fcc0ea':
          return t('pageListAssets.approval.name.waiting_verification')
        default:
          return t('pageListAssets.approval.name.waiting_validasi')
      }
    }

    return {
      getDetailAsset,
      t,
      images,
      getListPengguna,
      getListPengelolaan,
      namePengguna,
      namePengelolaan,
      datareal,
      forms,
      form,
      newForm,
      radio2,
      checked1,
      formatPrice,
      formatFileExt,
      formatDate,
      formatSize,
      checked2,
      country,
      checkAccess,
      openPreviewPDF,
      setPreviewImage,
      srcListImage,
      setPreviewPDF,
      fileDetail,
      polygonData,
      polylineData,
      tagDefineColor,
      getApproval,
      mapApproval,
      nameStatusApproval
    };
  },
});
