
import { defineComponent, inject, onMounted, ref } from 'vue';
import images from '@/assets/ts/images.js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'List of Assets',
  data() {
    return {
      iconClose: images.closeChips,
      assets: [
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Rejected',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Accepted',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Pending',
        },
        {
          nameAssets: 'Kantor Pusat DKI',
          categoryAssets: 'Tanah',
          location: 'DKI Jakarta',
          valueAssets: 'RP 200.000.000',
          bumn: 'PT POS Indonesia',
          status: 'Rejected',
        },
      ],
    };
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const images = inject('$images');
    const store = useStore();
    const setSelected = ref('');

    const book = ref('');
    const statusProduktifitas = ref('Produktif');
    const pengguna = ref('pengguna');
    const status_legalitas = ref('');
    const peruntukan = ref('');

    const options = [
      {
        value: 'Option1',
        label: '2021',
      },
      {
        value: 'Option2',
        label: '2020',
      },
      {
        value: 'Option3',
        label: '2019',
      },
      {
        value: 'Option4',
        label: '2018',
      },
      {
        value: 'Option5',
        label: '2017',
      },
    ];

    const curencyOption = [
      {
        value: 'Option1',
        label: '2021',
      },
      {
        value: 'Option2',
        label: '2020',
      },
      {
        value: 'Option3',
        label: '2019',
      },
      {
        value: 'Option4',
        label: '2018',
      },
      {
        value: 'Option5',
        label: '2017',
      },
    ];

    function setTarget(value) {
      setSelected.value = value;
    }
    onMounted(() => {
      const element: HTMLElement = document.querySelector(
        '.reportContainer .nav li:first-child a'
      ) as HTMLElement;
      if (element) {
        element.click();
      }
      setCurrentPageBreadcrumbs('mainMenuTop.listAsset', []);
    });
    return {
      images,
      t,
      setTarget,
      options,
      statusProduktifitas,
      peruntukan,
      status_legalitas,
      pengguna,
      book,
    };
  },
});
